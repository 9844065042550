import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import StayCardV6 from "components/StayCard/StayCardV6";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayoutV2 from "./CommonLayoutV2";
import { AppDispatch, RootState } from "redux/store";
import { getallupdateProperties } from "../../redux/reducers/Properties/PropertiesSlice";

export interface PropertyListProps {}

const PropertyList: FC<PropertyListProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { globalId } = useSelector((state: RootState) => state.property);
  const { slug } = useParams();
  const id = slug;
  const propertyId = id; // Get the property ID
  const RouteID =  id;

  const [propertyData, setPropertyData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (propertyId || id) {
      dispatch(getallupdateProperties({ propertyId })).then((response: any) => {
        setPropertyData(response.payload); // Assuming the response payload contains the property data
      });
    } else {
      console.log("No property ID found");
    }
  }, [dispatch, propertyId, id]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  return (
    <CommonLayoutV2 currentHref="/add-listing-9" PropertyID={RouteID}>
      <>
        <div>
          <h2 className="text-2xl font-semibold">Congratulations 🎉</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Excellent, congratulations on completing the listing, it is waiting
            to be reviewed for publication
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="listingSection__wrap">
          <h3 className="text-lg font-semibold">This is your listing</h3>
          <div className="max-w-xs">
            {isLoading ? (
              <div className="h-64 bg-neutral-300 dark:bg-neutral-700 rounded-lg animate-pulse"></div>
            ) : (
              propertyData && (
                <StayCardV6 className="mt-8" listingdata={propertyData} />
              )
            )}
          </div>
          <div className="flex items-center space-x-5 mt-8">
            <ButtonSecondary href={`/add-listing-1/${slug}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              <span className="ml-3">Edit</span>
            </ButtonSecondary>

            <ButtonPrimary href={`/listing-stay-detail/${propertyId}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
              <span className="ml-3">Preview</span>
            </ButtonPrimary>
          </div>
        </div>
        {/*  */}
      </>
    </CommonLayoutV2>
  );
};

export default PropertyList;
