// import React from "react";
// import "./CustomDatePicker.scss";
// import { CDatePicker } from "@coreui/react-pro";
// import { DisabledDate } from "@coreui/react-pro/dist/esm/components/calendar/types";
// import { format, parse } from "date-fns";

// interface CustomDatePickerV2Props {
//   date: string | Date | null | undefined;
//   onDateChange:
//     | ((date: Date | null, formatedDate?: string | undefined) => void)
//     | undefined;
//   disabledDates: DisabledDate | DisabledDate[] | undefined;
//   maxDate: string | Date | null | undefined;
//   minDate: string | Date | null | undefined;
//   placeholder: string
//   isCrossRequired?: boolean;
// }

// const CustomDatePickerV2: React.FC<CustomDatePickerV2Props> = ({
//   date,
//   onDateChange,
//   disabledDates,
//   minDate,
//   maxDate,
//   placeholder,
//   isCrossRequired,
// }) => {

//   return (
//     <CDatePicker
//       date={date}
//       onDateChange={onDateChange}
//       locale="en-US"
//       placeholder={placeholder}
//       inputDateParse={(date: any) => parse(date, "MMM dd, yyyy", new Date())}
//       inputDateFormat={(date: any) => format(new Date(date), "MMM dd, yyyy")}
//       disabledDates={disabledDates}
//       todayButton={false}
//       maxDate={maxDate}
//       minDate={minDate}
//     />
//   );
// };

// export default CustomDatePickerV2;

import React from "react";
import "./CustomDatePicker.scss";
import { DatePicker } from "react-rainbow-components";
import { Value } from "react-rainbow-components/components/DatePicker";

interface CustomDatePickerV2Props {
  value: Value | undefined;
  onChange: ((date: Date) => void) | undefined;
  disabledDays: (string | Date)[] | undefined;
  placeholder: string;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  isCrossRequired?: boolean;
  onBlur?: any;
  name?: string;
}

const CustomDatePickerV2: React.FC<CustomDatePickerV2Props> = ({
  value,
  onChange,
  disabledDays,
  placeholder,
  minDate,
  maxDate,
}) => {
  return (
    <DatePicker
      value={value}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      placeholder={placeholder}
      disabledDays={disabledDays}
      formatStyle="medium"
      className="my-custom-datepicker"
    />
  );
};

export default CustomDatePickerV2;
