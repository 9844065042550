import React, { FC, useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "redux/store";
import {
  getallupdateProperties,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import FormItem from "./FormItem";
import Input from "shared/Input/Input";
import { ListingDetailsvalidationSchema } from "utils/formSchema";
import CommonLayoutV2 from "./CommonLayoutV2";
import __countryListing from "../../data/jsons/__countryListing.json";
import "react-loading-skeleton/dist/skeleton.css";
export interface ListingDetailsProps {}

interface InitialValues {
  property_size: string;
  total_rooms: string;
  total_bedrooms: string;
  total_bathrooms: string;
  additionalRules: AdditionalRules[];
  roomies_attributes: any;
}

interface AdditionalRules {
  id: number | null;
  name: string;
}

const ListingDetails: FC<ListingDetailsProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { slug } = useParams();
  const id = slug;
  const RouteID = id;
  const [isEdit, setIsEdit] = useState<{
    state: boolean;
    itemId: number | null;
  }>({ state: false, itemId: null });
  const [initialValues, setInitialValues] = useState<InitialValues>({
    property_size: "",
    total_rooms: "",
    total_bedrooms: "",
    total_bathrooms: "",
    additionalRules: [],
    roomies_attributes: [],
  });
  const [newRuleInput, setNewRuleInput] = useState<string | never>("");
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const propertyId = id;
    if (propertyId) {
      dispatch(getallupdateProperties({ propertyId }))
        .unwrap()
        .then((response) => {
          if (response?.success && response.property) {
            setInitialValues({
              property_size: response?.property?.property_size || "",
              total_rooms: response?.property?.total_rooms || "",
              total_bedrooms: response?.property?.total_bedrooms || "",
              total_bathrooms: response?.property?.total_bathrooms || "",
              additionalRules: response?.property?.additional_rules,
              roomies_attributes: response?.property?.roomies,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching property data:", error);
        });
    }
  }, [dispatch, id]);

  const handleAddRule = (setFieldValue: any, values: any) => {
    if (newRuleInput?.length === 0) return null;

    let newRule = {
      id: Date.now(),
      name: newRuleInput,
      isNew: true,
    };

    if (isEdit.state) {
      const modifiedRule = values.additionalRules?.map((item: any) =>
        item.id === isEdit.itemId ? { ...item, name: newRuleInput } : item
      );
      setFieldValue("additionalRules", modifiedRule);
    } else {
      setFieldValue("additionalRules", [...values.additionalRules, newRule]);
    }
    setIsEdit({ state: false, itemId: null });
    setNewRuleInput("");
  };

  const handleRemoveRule = (id: number, setFieldValue: any, values: any) => {
    const remainingAdditionRule = values.additionalRules?.filter(
      (item: AdditionalRules) => item.id !== id
    );
    if (isEdit.state) {
      setNewRuleInput("");
    }
    setFieldValue("additionalRules", remainingAdditionRule);
    setIsEdit({ state: false, itemId: null });
  };

  const handleEditRule = (rule: any) => {
    if (rule?.name) {
      setIsEdit({ state: true, itemId: rule.id });
      setNewRuleInput(rule.name);
    }
  };

  const renderNoInclude = (
    rule: any,
    index: number,
    setFieldValue: any,
    values: any
  ) => (
    <div
      className="flex items-center justify-between py-3"
      key={`${index} ${rule.id}`}
    >
      <span className="text-neutral-600 dark:text-neutral-400 font-medium">
        {rule.name}
      </span>
      <div className="flex space-x-4">
        {/* Edit Button */}
        <button
          type="button"
          onClick={() => handleEditRule(rule)}
          className="text-2xl text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-600"
          title="Edit Rule"
        >
          <i className="las la-edit" />
        </button>

        {/* Delete Button */}
        <button
          type="button"
          onClick={() => handleRemoveRule(rule.id, setFieldValue, values)}
          className="text-2xl text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-600"
          title="Delete Rule"
        >
          <i className="las la-trash-alt" />
        </button>
      </div>
    </div>
  );

  const handleAddRoomie = (values: any, setFieldValue: any) => {
    setFieldValue('roomies_attributes', [...values.roomies_attributes, { id: Date.now(), name: "", gender: "", country: "", isAlready: false }])
  };

  const handleInputChange = (id: number, field: any, value: any, values: any, setFieldValue: any) => {
    const updatedRoomies = values.roomies_attributes.map((roomie: any) =>
    roomie.id === id ? { ...roomie, [field]: value } : roomie
  )
  setFieldValue('roomies_attributes', updatedRoomies)
  };

  const handleRemoveRoomie = (id: number, values: any, setFieldValue: any) => {
    setFieldValue('roomies_attributes',  values.roomies_attributes.filter((roomie: any) => roomie.id !== id))
  };

  const isDirty = (values: InitialValues, initialValues: InitialValues) =>
    (Object.keys(values) as (keyof InitialValues)[]).some(
      (key) => values[key] !== initialValues[key]
    );

  const submitHandler = (values: typeof initialValues) => {
    const dirty = isDirty(values, initialValues);
    const modifiedRoomies = values.roomies_attributes?.map((item: any) => ({
      name: item.name,
      gender: item.gender,
      country: item.country,
    }));

    const modified = values.additionalRules?.map((item: any) => ({
      ...item,
      id: null,
    }));

    const payload = {
      id: id,
      property_size: values.property_size,
      total_rooms: values.total_rooms,
      total_bathrooms: values.total_bathrooms,
      total_bedrooms: values.total_bedrooms,
      additional_rules_attributes: modified,
      roomies_attributes: modifiedRoomies,
    };

    if (dirty) {
      dispatch(updateProperties({ property: payload }))
        .unwrap()
        .then((response) => {
          if (response?.success) {
            successHandler(response?.message || "Updated successfully");
            navigate(`/add-listing-5/${slug}`);
          } else {
            console.log("Operation failed: ", response);
          }
        })
        .catch((error) => {
          console.log("Error in operation: ", error);
        })
    } else {
      navigate(`/add-listing-5/${slug}`);
    }
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return (
    <CommonLayoutV2 currentHref="/add-listing-4" PropertyID={RouteID}>
      <>
        <h2 className="text-2xl font-semibold">Size of your location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={ListingDetailsvalidationSchema}
          onSubmit={submitHandler}
        >
          {({
            errors,
            values,
            touched,
            isSubmitting,
            getFieldProps,
            setFieldValue,
            handleSubmit
          }) => {
            return (
              <div className="space-y-8">
                {/* Property Size */}
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <FormItem label="Property Size (m²)">
                      <Input
                        type="text"
                        placeholder="Enter property size"
                        {...getFieldProps("property_size")}
                      />
                      {errors.property_size && touched.property_size && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.property_size}
                        </div>
                      )}
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem label="Rooms">
                      <Input
                        type="text"
                        placeholder="Enter number of rooms"
                        {...getFieldProps("total_rooms")}
                      />
                      {errors.total_rooms && touched.total_rooms && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.total_rooms}
                        </div>
                      )}
                    </FormItem>
                  </div>
                </div>

                {/* Bedroom and Bathroom */}
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <FormItem label="Bedroom">
                      <Input
                        type="text"
                        placeholder="Enter number of bedrooms"
                        {...getFieldProps("total_bedrooms")}
                      />
                      {errors.total_bedrooms && touched.total_bedrooms && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.total_bedrooms}
                        </div>
                      )}
                    </FormItem>
                  </div>
                  <div className="flex-1">
                    <FormItem label="Bathroom">
                      <Input
                        type="text"
                        placeholder="Enter number of bathrooms"
                        {...getFieldProps("total_bathrooms")}
                      />
                      {errors.total_bathrooms && touched.total_bathrooms && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.total_bathrooms}
                        </div>
                      )}
                    </FormItem>
                  </div>
                </div>

                {/* Property Rules */}
                <h3 className="text-lg font-semibold">Property Rules</h3>
                <div>
                  <div>
                    {loading ? (
                      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        {Array.from({
                          length: values.additionalRules.length || 6,
                        }).map((_, index) => (
                          <div
                            key={index}
                            className="h-10 bg-neutral-300 dark:bg-neutral-700 rounded animate-pulse"
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="-my-3 mt-1 divide-y divide-neutral-100 dark:divide-neutral-800">
                        {values.additionalRules.map((rule, index) =>
                          renderNoInclude(rule, index, setFieldValue, values)
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col sm:space-y-0 sm:space-x-5">
                  <Input
                    type="text"
                    ref={null}
                    className="input-field !h-20 !w-3/4"
                    placeholder="No smoking..."
                    value={newRuleInput}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      return setNewRuleInput(e.target.value)
                    }}
                  />
                  <ButtonPrimary
                    type="button"
                    className="flex-shrink-0 !w-32 !mt-5"
                    onClick={() => handleAddRule(setFieldValue, values)}
                  >
                    <i className="text-xl las la-plus"></i>
                    <span className="mr-4">Add</span>
                  </ButtonPrimary>
                </div>

                <div>
                  <h3 className="text-lg font-semibold mb-4">
                    Who Lives Here?
                  </h3>
                  <div className="overflow-x-auto">
                    <table className="table-auto border-collapse w-full">
                      <thead>
                        <tr className="bg-orange-500 text-white">
                          <th className="border px-4 py-2">Name</th>
                          <th className="border px-4 py-2">Gender</th>
                          <th className="border px-4 py-2">Country</th>
                          <th className="border px-4 py-2">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading
                          ? Array.from({ length: values?.roomies_attributes.length || 2 }).map(
                              (_, index) => (
                                <tr key={index}>
                                  <td className="border px-4 py-2 bg-neutral-100 dark:bg-black">
                                    <div className="animate-pulse">
                                      <div className="h-10 bg-neutral-300 dark:bg-neutral-700 rounded"></div>
                                    </div>
                                  </td>
                                  <td className="border px-4 py-2 bg-neutral-100 dark:bg-black">
                                    <div className="animate-pulse">
                                      <div className="h-10 bg-neutral-300 dark:bg-neutral-700 rounded"></div>
                                    </div>
                                  </td>
                                  <td className="border px-4 py-2 bg-neutral-100 dark:bg-black">
                                    <div className="animate-pulse">
                                      <div className="h-10 bg-neutral-300 dark:bg-neutral-700 rounded"></div>
                                    </div>
                                  </td>
                                  <td className="border px-4 py-2 bg-neutral-100 dark:bg-black">
                                    <div className="animate-pulse">
                                      <div className="h-10 w-1/2 bg-neutral-300 dark:bg-neutral-700 rounded"></div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )
                          : values?.roomies_attributes?.map((roomie: any) => (
                              <tr key={roomie.id}>
                                <td className="border px-4 py-2">
                                  <input
                                    type="text"
                                    value={roomie.name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        roomie.id,
                                        "name",
                                        e.target.value, 
                                        values,
                                        setFieldValue, 
                                      )
                                    }
                                    className="w-full px-2 py-1 border rounded"
                                  />
                                </td>
                                <td className="border px-4 py-2">
                                  <select
                                    value={roomie.gender}
                                    onChange={(e) =>
                                      handleInputChange(
                                        roomie.id,
                                        "gender",
                                        e.target.value, 
                                        values,
                                        setFieldValue, 
                                      )
                                    }
                                    className="w-full min-w-[100px] px-4 py-1 border rounded"
                                  >
                                    <option value="">Select</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                  </select>
                                </td>
                                <td className="border px-4 py-2">
                                  <select
                                    value={roomie.country}
                                    onChange={(e) =>
                                      handleInputChange(
                                        roomie.id,
                                        "country",
                                        e.target.value, 
                                        values,
                                        setFieldValue, 
                                      )
                                    }
                                    className="w-full px-2 py-1 border rounded"
                                  >
                                    <option value="">Select</option>
                                    {__countryListing.map((country) => (
                                      <option
                                        key={country.name}
                                        value={country.name}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td className="border px-4 py-2">
                                  <button
                                    onClick={() => {
                                      return handleRemoveRoomie(roomie.id, values, setFieldValue)
                                    }}
                                    className="bg-red-500 text-white px-4 py-1 rounded"
                                  >
                                    Remove
                                  </button>
                                </td>
                                {(!roomie.name ||
                                  !roomie.gender ||
                                  !roomie.country) && (
                                  <div className="ml-2">
                                    <span className="text-sm text-red-600">
                                      Details are required
                                    </span>
                                  </div>
                                )}
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>

                  <ButtonPrimary
                    type="button"
                    className="flex-shrink-0 w-48 !mt-5 flex items-center space-x-3"
                    onClick={() => handleAddRoomie(values, setFieldValue)}
                  >
                    <i className="text-xl las la-plus"></i>
                    <span className="ml-3">Add Roomie</span>
                  </ButtonPrimary>
                </div>

                {/* Buttons */}
                <div className="flex justify-end space-x-5">
                  <ButtonSecondary href={`/add-listing-3/${slug}`}>
                    Go back
                  </ButtonSecondary>
                  <ButtonPrimary
                    type="button"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {isSubmitting ? "Loading..." : "Continue"}
                  </ButtonPrimary>
                </div>
              </div>
            );
          }}
        </Formik>
      </>
    </CommonLayoutV2>
  );
};

export default ListingDetails;
