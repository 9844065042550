import React from "react";
import { useDispatch } from "react-redux";
import { RootState, AppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { bookingQueries } from "../../../redux/reducers/UserSlice/UserSlice";
import { useParams } from "react-router-dom";
import { booking } from "../../../redux/reducers/UserSlice/UserSliceType";
import successHandler from "utils/helpers/SuccessHandler";
import CustomDatePickerV2 from "shared/DateField/CustomDatePickerV2";
import { format } from "date-fns";

interface ContactOwnerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSend: (data: booking) => void;
  propertyData?: any;
  currentRoomId?: any;
}

const ContactOwnerModal: React.FC<ContactOwnerModalProps> = ({
  isOpen,
  onClose,
  onSend,
  propertyData = {},
  currentRoomId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { globalId } = useSelector((state: RootState) => state.property);
  const { slug } = useParams();
  const id = slug;
  const propertyId = id;
  const isSharedProperty = propertyData.is_shared_property;
  const rooms = propertyData?.rooms?.map((room: any, idx: number) => ({
    data: `Room ${idx + 1}`,
    value: room?.id,
  }));

  const currentRoom = isSharedProperty
    ? propertyData?.rooms?.find((item: any) => item.id === currentRoomId)
    : null;

  // const validationSchema = Yup.object({
  //   check_in_date: Yup.date()
  //   .required("Check In date is required")
  //   .test(
  //     "not-before-availableStart",
  //     "Check In date cannot be before available start date",
  //     (value) => value && new Date(value) >= new Date(availableStart)
  //   )
  //   .test(
  //     "not-after-availabilityEnds",
  //     "Check In date cannot be after availability end.",
  //     (value) => value && new Date(value) <= new Date(availabilityEnds)
  //   ),

  // check_out_date: Yup.date()
  //   .required("Check Out date is required")
  //   .test(
  //     "after-check-in",
  //     "Check Out date should be after Check In date",
  //     function (value) {
  //       const { check_in_date } = this.parent;
  //       return value && new Date(value) > new Date(check_in_date);
  //     }
  //   )
  //   .test(
  //     "not-before-availableStart",
  //     "Check Out date cannot be before available start date",
  //     (value) => value && new Date(value) >= new Date(availableStart)
  //   )
  //   .test(
  //     "not-after-availabilityEnds",
  //     "Check Out date cannot be after availability end",
  //     (value) => value && new Date(value) <= new Date(availabilityEnds)
  //   ),
  //   guest_count: Yup.number()
  //     .min(1, "At least one guest is required")
  //     .required("Number of guests is required"),
  //   query_for: Yup.string().nullable().when("isSharedProperty", {
  //     is: true,
  //     then: (schema) => schema.required("Please select a room"),
  //     otherwise: (schema) => schema.notRequired(),
  //   }),
  //   query: Yup.string().max(500, "Message must be 500 characters or less"),
  // });

  const formik = useFormik({
    initialValues: {
      check_in_date: "",
      check_out_date: "",
      guest_count: 1,
      query_for: "",
      query: "",
    },

    // validationSchema,
    onSubmit: async (values) => {
      const queryFor =
        isSharedProperty && values.query_for
          ? { room_number: [values.query_for] }
          : null;

      onSend(values);

      try {
        await dispatch(
          bookingQueries({
            ...values,
            query_for: queryFor,
            property_id: propertyId,
          })
        ).unwrap();
        successHandler(
          "Your message was sent! You will be notified by email when a reply is received."
        );
        onClose();
      } catch (error) {
        console.error("Failed to dispatch booking query:", error);
      }

      onClose();
    },
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 dark:bg-black-800 dark:bg-opacity-75 z-40 backdrop-blur-md">
      <div className="enquiry-modal bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6 w-full max-w-md relative mt-16 z-50 mr-7">
        <button
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-100 focus:outline-none"
          onClick={onClose}
        >
          ×
        </button>
        <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-2 text-center">
          Contact the owner
        </h2>
        <p className="text-gray-500 dark:text-gray-400 text-center mb-4">
          Please complete the form below to contact the owner.
        </p>

        <form onSubmit={formik.handleSubmit}>
          <label className="block">
            <span className="text-gray-700 dark:text-gray-300">Check In</span>
            {/* <CustomDatePickerV2
              value={
                formik.values.check_in_date
                  ? dayjs(formik.values.check_in_date)
                  : null
              }
              onChange={(date: any) => {
                const formattedDate = date ? date.format("YYYY-MM-DD") : null;
                formik.setFieldValue("check_in_date", formattedDate);
              }}
              disabled={false}
              placeholder="Select Start Date"
              isCrossRequired={true}
              disabledDate={(current) => {
                const today = dayjs().startOf("day");
                const availabilityS = !isSharedProperty
                  ? propertyData.availability_start
                  : currentRoom?.booking_start;
                const availabilityE = !isSharedProperty
                  ? propertyData.availability_end
                  : currentRoom?.booking_end;
                const availabilityStart = dayjs(availabilityS).startOf("day");
                const availabilityEnd = dayjs(availabilityE).startOf("day");
                // const bookedDates = !isSharedProperty ? propertyData.booked_dates : currentRoom?.booked_dates || [];
                // const isDateBooked = bookedDates.some((range: any) => {
                //   if (!Array.isArray(range) || range.length !== 2) {
                //     return false; // Skip invalid ranges
                //   }

                //   const [start, end] = range as [string, string]; // Type assertion
                //   const startDate = dayjs(start).startOf("day");
                //   const endDate = dayjs(end).startOf("day");
                //   return (
                //     current &&
                //     current.isBetween(startDate, endDate, "day", "[]")
                //   ); // Inclusive range
                // });

                return (
                  (current && current.isBefore(today)) ||
                  (current && current.isBefore(availabilityStart)) || (current && current.isAfter(availabilityEnd)) || false
                );
              }}
            /> */}
            {/* <CustomDatePickerV2
              date={formik.values.check_in_date}
              onDateChange={(date: any) => {
                const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
                formik.setFieldValue("check_in_date", formattedDate);
              }}
              disabledDates={[]}
              placeholder="Select Start Date"
              minDate={
                new Date(
                  !isSharedProperty
                    ? propertyData.availability_start
                    : currentRoom?.booking_start
                ) > new Date()
                  ? new Date(
                      !isSharedProperty
                        ? propertyData.availability_start
                        : currentRoom?.booking_start
                    )
                  : new Date()
              }
              maxDate={
                new Date(
                  !isSharedProperty
                    ? propertyData.availability_end
                    : currentRoom?.booking_end
                )
              }
              isCrossRequired={true}
            /> */}

            <CustomDatePickerV2
              value={formik.values.check_in_date || ""}
              onChange={(value) => {
                const formattedDate = value
                  ? format(value, "yyyy-MM-dd")
                  : null;
                formik.setFieldValue("check_in_date", formattedDate);
              }}
              placeholder="Select Start Date"
              minDate={
                new Date(
                  !isSharedProperty
                    ? propertyData.availability_start
                    : currentRoom?.booking_start
                ) > new Date()
                  ? new Date(
                      !isSharedProperty
                        ? propertyData.availability_start
                        : currentRoom?.booking_start
                    )
                  : new Date()
              }
              maxDate={
                new Date(
                  !isSharedProperty
                    ? propertyData?.availability_end ?? ''
                    : currentRoom?.booking_end ?? ''
                )
              }
              disabledDays={[]}
              isCrossRequired={true}
            />
          </label>

          <label className="block">
            <span className="text-gray-700 dark:text-gray-300">Check Out</span>
            {/* <CustomDatePickerV2
              value={
                formik.values.check_out_date
                  ? dayjs(formik.values.check_out_date)
                  : null
              }
              onChange={(date: any) => {
                const formattedDate = date ? date.format("YYYY-MM-DD") : null;
                formik.setFieldValue("check_out_date", formattedDate);
              }}
              placeholder="Select End Date"
              isCrossRequired={true}
              disabledDate={(current) => {
                if (!current) return true;
                const today = dayjs().startOf("day");
                const availabilityE = !isSharedProperty
                  ? propertyData.availability_end
                  : currentRoom?.booking_end;

                const availabilityA = !isSharedProperty
                  ? propertyData.availability_start
                  : currentRoom?.booking_start;
                // const checkInDate = dayjs(formik.values.check_in_date).startOf(
                //   "day"
                // );
                // const availabilityEnd = dayjs(availabilityE).startOf("day");
                // const minimumMonths =
                //   propertyData?.minimum_months_of_booking || 0;
                  // const bookedDates = !isSharedProperty ? propertyData.booked_dates : currentRoom?.booked_dates || [];
                // const minCheckOutDate = checkInDate.add(minimumMonths, "month");

                // const checkInDate = dayjs(formik.values.check_in_date).startOf("day");
                const availabilityStart = dayjs(availabilityA).startOf("day");
                const availabilityEnd = dayjs(availabilityE).startOf("day");
                // const minimumMonths =
                //   propertyData?.minimum_months_of_booking || 0;
                // const bookedDates = !isSharedProperty ? propertyData.booked_dates : currentRoom?.booked_dates || [];
              
                // const minCheckOutDate = checkInDate.add(minimumMonths, "month").startOf("day");

                // const isDateBooked = bookedDates.some(([start, end]: [string, string]) => {
                //   const startDate = dayjs(start).startOf("day");
                //   const endDate = dayjs(end).startOf("day");
                //   return current.isBetween(startDate, endDate, "day", "[]");
                // });
              
                // // Check for dates before the first booked date
                // const violatesMinimumMonthsBeforeBooked = bookedDates.some(([start]: [string, string]) => {
                //   const startDate = dayjs(start).startOf("day");
                //   const minDateBeforeBooked = startDate.subtract(minimumMonths, "month").startOf("day");
                //   return current.isBefore(minDateBeforeBooked);
                // });
              
                // Check for dates after the last booked date
                // const violatesMinimumMonthsAfterBooked = bookedDates.some(([_, end]: [string, string]) => {
                //   const endDate = dayjs(end).startOf("day");
                //   const minDateAfterBooked = endDate.add(minimumMonths, "month").startOf("day");
                //   return current.isAfter(minDateAfterBooked);
                // });
              
                // Explicitly disable availability_end if it doesn't satisfy the minimum month criteria
                // const violatesMinimumMonthsAtEnd = current.isSame(availabilityEnd, "day") &&
                //   availabilityEnd.subtract(minimumMonths, "month").isBefore(current);
              
                return (
                  current.isBefore(today) ||
                  current.isBefore(availabilityStart) ||
                  current.isAfter(availabilityEnd)
                );
              }}
            /> */}
            {/* <CustomDatePickerV2
              date={formik.values.check_out_date}
              onDateChange={(date: any) => {
                const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
                formik.setFieldValue("check_out_date", formattedDate);
              }}
              disabledDates={[]}
              placeholder="Select End Date"
              minDate={
                new Date(
                  !isSharedProperty
                    ? propertyData.availability_start
                    : currentRoom?.booking_start
                ) > new Date()
                  ? new Date(
                      !isSharedProperty
                        ? propertyData.availability_start
                        : currentRoom?.booking_start
                    )
                  : new Date()
              }
              maxDate={
                new Date(
                  !isSharedProperty
                    ? propertyData.availability_end
                    : currentRoom?.booking_end
                )
              }
              isCrossRequired={true}
            /> */}

            <CustomDatePickerV2
              value={formik.values.check_out_date || ""}
              onChange={(value) => {
                const formattedDate = value
                  ? format(value, "yyyy-MM-dd")
                  : null;
                formik.setFieldValue("check_out_date", formattedDate);
              }}
              placeholder="Select End Date"
              minDate={
                new Date(
                  !isSharedProperty
                    ? propertyData.availability_start
                    : currentRoom?.booking_start
                ) > new Date()
                  ? new Date(
                      !isSharedProperty
                        ? propertyData.availability_start
                        : currentRoom?.booking_start
                    )
                  : new Date()
              }
              maxDate={
                new Date(
                  !isSharedProperty
                    ? propertyData.availability_end ?? ''
                    : currentRoom?.booking_end ?? ''
                )
              }
              disabledDays={[]}
              isCrossRequired={true}
            />
          </label>

          <label className="block">
            <span className="text-gray-700 dark:text-gray-300">Guests</span>
            <select
              name="guest_count"
              value={formik.values.guest_count}
              onChange={formik.handleChange}
              className="mt-1 block w-full rounded-[20px] border-gray-400 dark:border-gray-700 shadow-sm focus:border-indigo-300 dark:focus:border-indigo-500 focus:ring focus:ring-indigo-200 dark:focus:ring-indigo-600 focus:ring-opacity-50 dark:black dark:text-gray-300"
            >
              {Array.from({ length: 10 }, (_, n) => (
                <option key={n} value={n + 1}>
                  {n + 1} Guest
                </option>
              ))}
            </select>
            {formik.touched.guest_count && formik.errors.guest_count && (
              <div className="text-red-500 text-sm">
                {formik.errors.guest_count}
              </div>
            )}
          </label>

          {isSharedProperty && (
            <label className="block">
              <span className="text-gray-700 dark:text-gray-300">
                Room-Number
              </span>
              <select
                name="query_for"
                value={formik.values.query_for}
                onChange={formik.handleChange}
                className="mt-1 block w-full rounded-[20px] border-gray-400 dark:border-gray-700 shadow-sm focus:border-indigo-300 dark:focus:border-indigo-500 focus:ring focus:ring-indigo-200 dark:focus:ring-indigo-600 focus:ring-opacity-50 dark:black dark:text-gray-300"
              >
                <option value="">Select number of rooms</option>
                {rooms.length > 0 ? (
                  rooms.map((room: any) => (
                    <option key={room.value} value={room.value}>
                      {room.data}
                    </option>
                  ))
                ) : (
                  <option disabled>No rooms available</option>
                )}
              </select>
              {formik.touched.query_for && formik.errors.query_for && (
                <div className="text-red-500 text-sm">
                  {formik.errors.query_for}
                </div>
              )}
            </label>
          )}

          <label className="block">
            <span className="text-gray-700 dark:text-gray-300">Query</span>
            <textarea
              name="query"
              value={formik.values.query}
              onChange={formik.handleChange}
              placeholder="Your message"
              className="mt-1 block w-full rounded-md border-gray-400 dark:border-gray-700 shadow-sm focus:border-indigo-300 dark:focus:border-indigo-500 focus:ring focus:ring-indigo-200 dark:focus:ring-indigo-600 focus:ring-opacity-50 dark:black dark:text-gray-300"
            />
            {formik.touched.query && formik.errors.query && (
              <div className="text-red-500 text-sm">{formik.errors.query}</div>
            )}
          </label>

          <button
            type="submit"
            className="w-full py-2 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 dark:focus:ring-offset-gray-900 dark:bg-orange-400 dark:hover:bg-orange-500 mt-4"
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactOwnerModal;
