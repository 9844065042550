import { FC, Fragment, useEffect, useState } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//@ts-ignore
import { Dialog, Transition } from "@headlessui/react";
//@ts-ignore
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import { getallupdateProperties } from "../../../redux/reducers/Properties/PropertiesSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { useSelector } from "react-redux";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";
import { reserveBooking } from "../../../redux/reducers/Payments/PaymentReducer";
import ContactOwnerModal from "./contact-owner";
import successHandler from "utils/helpers/SuccessHandler";
import ErrorHandler from "utils/helpers/ErrorHandler";
import SelectGuests from "./SelectGuests";
import moment from "moment";
import { useFormik } from "formik";
import FormItem from "containers/PageAddListing1/FormItem";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import NcImage from "shared/NcImage/NcImage";
import GallerySliderV3 from "components/GallerySlider/GallerySliderV3";
import { currencySymbol } from "shared/CurrencySymbol";
import { format } from "date-fns";
import CustomDatePickerV2 from "shared/DateField/CustomDatePickerV2";

const StayDetailPageContainer: FC<{}> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [propertyData, setPropertyData] = useState<PropertyToEdit | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { globalId } = useSelector((state: RootState) => state.property);
  const [address, setAddress] = useState<any>(null);
  const { slug } = useParams();
  const id = slug;
  const propertyId = globalId || slug;
  const totalTax = propertyData?.property_taxes.reduce(
    (acc, tax) => acc + tax.value,
    0
  );
  const [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const thisPathname = useLocation().pathname;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleChatButtonClick: () => void = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleSendMessage = (data: any) => {
    setIsModalOpen(false);
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    if (!propertyData) return;
    const data = JSON.stringify(propertyData?.place_images);
    navigate(
      `${thisPathname}/?type=PROPERTY_IMAGES&data=${encodeURIComponent(data)}`,
      {
        replace: true,
      }
    );
  };

  const fetchAddress = async (latitude: number, longitude: number) => {
    const geocodeUrl = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${latitude}%2C${longitude}&lang=en-US&apiKey=${process.env.REACT_APP_LOCATION_KEY}`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();
      const address = data?.items[0]?.address;
      if (address) {
        setAddress(address);
      } else {
        console.error("No address found");
      }
    } catch (error) {
      console.error("Error fetching geocode data:", error);
    }
  };

  useEffect(() => {
    const fetchPropertyData = async () => {
      if (propertyId || id) {
        try {
          setLoading(true);
          const response: any = await dispatch(
            getallupdateProperties({ propertyId })
          );
          const property = response.payload?.property;
          const { latitude, longitude } = property;
          fetchAddress(latitude, longitude);

          // Check for property data in response.payload
          if (property) {
            setPropertyData(property);
          } else {
            console.error("Property not found:", response);
          }
        } catch (error) {
          console.error("Error fetching property data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPropertyData();
  }, [propertyId, id, dispatch]);

  if (loading) {
    return (
      <div className="flex items-center justify-center py-10">
        <div className="flex items-center justify-center space-x-2">
          <div className="w-10 h-10 border-4 border-t-4 border-gray-300 border-t-primary-6000 rounded-full animate-spin"></div>
          {/* <div className="text-neutral-500">Loading property data...</div> */}
        </div>
        {/* Optionally, add a spinner or loading animation here */}
      </div>
    );
  }

  const renderSection1 = () => {
    if (!propertyData) return null;

    return (
      <div
        className={`listingSection__wrap ${
          propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
        }`}
      >
        <div className="flex justify-between items-center">
          <Badge name={propertyData.property_type?.name || "Property"} />
          <LikeSaveBtns />
          {propertyData?.user?.is_host &&
            currentUser?.id === propertyData?.user?.id && (
              <ButtonSecondary href={`/add-listing-1/${slug}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
                <span className="ml-3">Edit</span>
              </ButtonSecondary>
            )}
        </div>

        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {propertyData.title}
        </h2>

        <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">{propertyData.address}</span>
          </span>
        </div>

        <div className="flex items-center">
          <Avatar
            hasChecked
            sizeClass="h-10 w-10"
            radius="rounded-full"
            imgUrl={propertyData.user?.image}
          />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {`${propertyData.user?.first_name} ${propertyData.user?.last_name}`}
            </span>
          </span>
        </div>

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3">
            <i className="las la-user text-2xl"></i>
            <span>
              {propertyData.guest_number}{" "}
              <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-door-open text-2xl"></i>
            <span>
              {propertyData.total_rooms}{" "}
              <span className="hidden sm:inline-block">rooms</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-bath text-2xl"></i>
            <span>
              {propertyData.total_bathrooms}{" "}
              <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-expand-arrows-alt text-2xl"></i>
            <span>
              {propertyData.property_size}{" "}
              <span className="hidden sm:inline-block">sq ft</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const RenderSection2 = () => {
    const [showFullDescription, setShowFullDescription] = useState(false);
    if (!propertyData) return null;
    const toggleDescription = () => {
      setShowFullDescription(!showFullDescription);
    };

    const truncatedDescription =
      propertyData?.description?.length > 150
        ? propertyData.description.substring(0, 150) + "..."
        : propertyData?.description || "";

    if (!propertyData) return null;

    return (
      <div
        className={`listingSection__wrap ${
          propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
        }`}
      >
        <h2 className="text-2xl font-semibold">Property Description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300 break-words">
          <span>
            {showFullDescription
              ? propertyData?.description
              : truncatedDescription}
          </span>
          <div className="mt-5">
            {propertyData?.description?.length > 150 && (
              <ButtonPrimary onClick={toggleDescription} className="">
                {showFullDescription ? "Show Less" : "Show More"}
              </ButtonPrimary>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    if (!propertyData) return null;
    const data = [
      ...propertyData?.features,
      ...propertyData?.amenities.map((amenity) => ({
        ...amenity,
        feature_type: "amenity",
      })),
    ];

    const showViewMore = data?.length > 6;
    const displayItems = showViewMore ? data.slice(0, 6) : data;

    return (
      <div
        className={`listingSection__wrap ${
          propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
        }`}
      >
        <div>
          <h2 className="text-2xl font-semibold">Features & Amenities</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's amenities and services
          </span>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300">
          {displayItems.map((item) => (
            <div key={item.id} className="flex items-center space-x-3">
              <i className="text-3xl las la-check-circle"></i>
              <span>{item.name}</span>
            </div>
          ))}
        </div>

        {showViewMore && (
          <>
            <div className="w-14 border-b border-neutral-200"></div>
            <div>
              <ButtonSecondary onClick={openModalAmenities}>
                View more amenities
              </ButtonSecondary>
            </div>
            {renderMotalAmenities()}
          </>
        )}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    if (!propertyData) return null;
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {propertyData.features.map((feature) => (
                      <div
                        key={feature.id}
                        className="flex items-center space-x-3 p-3"
                      >
                        <i className="text-3xl las la-check-circle"></i>
                        <span>{feature.name}</span>
                      </div>
                    ))}
                    {propertyData.amenities.map((amenities) => (
                      <div
                        key={amenities.id}
                        className="flex items-center space-x-3 p-3"
                      >
                        <i className="text-3xl las la-check-circle"></i>
                        <span>{amenities.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  // const renderSection4 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <div>
  //         <h2 className="text-2xl font-semibold">Room Rates </h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           Prices may increase on weekends or holidays
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* CONTENT */}
  //       <div className="flow-root">
  //         <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Monday - Thursday</span>
  //             <span>$199</span>
  //           </div>
  //           <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Monday - Thursday</span>
  //             <span>$199</span>
  //           </div>
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Friday - Sunday</span>
  //             <span>$219</span>
  //           </div>
  //           <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Rent by month</span>
  //             <span>-8.34 %</span>
  //           </div>
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Minimum number of nights</span>
  //             <span>1 night</span>
  //           </div>
  //           <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Max number of nights</span>
  //             <span>90 nights</span>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderSection5 = () => {
    if (!propertyData) return null;
    return (
      <div
        className={`listingSection__wrap ${
          propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
        }`}
      >
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            sizeClass="h-10 w-10"
            radius="rounded-full"
            imgUrl={propertyData.user?.image}
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              <span className="text-neutral-900 dark:text-neutral-200 font-medium">
                {`${propertyData.user?.first_name} ${propertyData.user?.last_name}`}
              </span>
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              {/* <span className="mx-2">·</span>
              <span> 12 places</span> */}
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="/author">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div
        className={`listingSection__wrap ${
          propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
        }`}
      >
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div
        className={`listingSection__wrap ${
          propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
        }`}
      >
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {address !== null ? address?.label : "Not available"}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps?q=${propertyData?.latitude},${
                propertyData?.longitude
              }&z=${15}&output=embed`}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div
        className={`listingSection__wrap ${
          propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
        }`}
      >
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {propertyData?.cancellation_policy?.description}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const RenderSection9 = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRoomId, setCurrentRoomId] = useState<any>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    dayjs.extend(isBetween);

    const handleSubmit = async (values: any) => {
      setIsLoading(true);
      const postData = {
        booking: {
          number_of_guests: Number(values.number_of_guests),
          property_id: propertyId,
          check_in_date: moment(values.booking_start).format("YYYY-MM-DD"),
          check_out_date: moment(values.booking_end).format("YYYY-MM-DD"),
          line_items_attributes: [
            {
              room_id: values.room_id,
              price: values.price_per_month,
              quantity: 1,
              property_id: propertyId,
            },
          ],
        },
      };

      try {
        const action = await dispatch(reserveBooking(postData as any));
        if (
          action?.type === "payment/reserveBooking/fulfilled" &&
          action.payload
        ) {
          successHandler(
            "Booking request sent. Please ! wait for owner's confirmation!!"
          );
        }
      } catch (error) {
        ErrorHandler("Failed to reserve booking. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    const formik = useFormik({
      initialValues: {
        number_of_guests: null,
        booking_start: null,
        booking_end: null,
        room_id: null,
        line_items_attributes: [],
        price_per_month: null,
      },
      enableReinitialize: true,
      onSubmit: (values) => {
        return handleSubmit(values);
      },
    });

    const selectedRoom = formik?.values?.room_id;

    const handleContactOwner = (roomId: number) => {
      setCurrentRoomId(roomId);
      setIsModalOpen(true);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    useEffect(() => {
      const { booking_start, booking_end, number_of_guests } = formik.values;
      if (booking_start && booking_end && number_of_guests) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }, [formik.values]);

    if (!propertyData) return null;
    return (
      <div className="listingSection__wrap !space-y-10">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          Rooms
        </h2>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        {propertyData.rooms.map((room, index) => (
          <div
            key={room.id}
            className={`${
              propertyData?.is_shared_property
                ? "flex flex-col lg:flex-row gap-4 w-full justify-between border border-gray-300/30 p-8 rounded-inherit mb-16"
                : ""
            }`}
          >
            <div
              className={`${
                propertyData?.is_shared_property ? "w-full lg:w-3/4 p-4" : ""
              }`}
            >
              <header className="rounded-md sm:rounded-xl">
                <div className="relative grid grid-cols-1">
                  <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-5">
                    {room?.name}
                  </h2>
                  <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                  <div className="relative w-full rounded-2xl overflow-hidden will-change-transform">
                    <GallerySliderV3
                      uniqueID={`ExperiencesCard_${id}`}
                      ratioClass="aspect-w-4 aspect-h-3"
                      galleryImgs={room?.room_images.map((image) => image.url)}
                    />
                  </div>
                </div>
              </header>

              <div className="flex justify-between items-center mb-4 mt-5">
                <Badge name={room.status || "Property"} />
              </div>
              <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300 mb-8">
                <div className="flex items-center space-x-3">
                  <i className="las la-user text-2xl"></i>
                  <span>{room.max_guests || "Default Value"}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <i className="las la-door-open text-2xl"></i>
                  <span>{room.room_type?.name || "Room Type"}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <i className="las la-bed text-2xl"></i>
                  <span>{room.bed_type?.name || "Bed Type"}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <i className="las la-expand-arrows-alt text-2xl"></i>
                  <span>{room.size} sq ft</span>
                </div>
              </div>
              <div className="listingSection__wrap mb-8 w-full max-w-full">
                <h2 className="text-2xl font-semibold">Room Description</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-4"></div>
                <div className="text-neutral-6000 dark:text-neutral-300 break-words">
                  <span>{room.description}</span>
                </div>
              </div>
              {propertyData?.is_shared_property && (
                <div className="mb-8">
                  <SectionDateRange
                    availabilityStart={new Date(
                      room.booking_start || Date.now()
                    ).getTime()}
                    availabilityEnd={new Date(
                      room.booking_end || Date.now()
                    ).getTime()}
                    booked_dates={room.booked_dates}
                  />
                </div>
              )}
              <div className="listingSection__wrap mb-8">
                <div>
                  <h2 className="text-2xl font-semibold">
                    Rooms Features & Amenities
                  </h2>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    About the property's amenities and services
                  </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-6"></div>

                <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300">
                  {room.features.map((feature) => (
                    <div
                      key={feature.id}
                      className="flex items-center space-x-3"
                    >
                      <i className="text-3xl las la-check-circle"></i>
                      <span>{feature.name}</span>
                    </div>
                  ))}
                  {room.amenities.slice(0, 3).map((amenity) => (
                    <div
                      key={amenity.id}
                      className="flex items-center space-x-3"
                    >
                      <i className="text-3xl las la-check-circle"></i>
                      <span>{amenity.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={`${
                propertyData?.is_shared_property ? "w-full lg:w-1.8/4 pt-4" : ""
              }`}
            >
              {propertyData?.is_shared_property && (
                <div className="listingSectionSidebar__wrap shadow-xl">
                  {room.status === "inactive" && (
                    <Badge
                      name={
                        room.status === "inactive" ? "Room is Inactive" : ""
                      }
                    />
                  )}
                  <div className="flex justify-between">
                    <span className="text-3xl font-semibold">
                      {`${currencySymbol()} ${
                        Number(room?.price_per_month)?.toFixed(2) || 0
                      }`}
                      /month
                    </span>
                  </div>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl"
                  >
                    <div className="pl-6 pr-6 pt-0 pb-6">
                      <FormItem
                        label="Availability Start Date"
                        className="mt-4"
                      >
                        {/* <CustomDatePickerV2
                          value={
                            formik.values.booking_start
                              ? dayjs(formik.values.booking_start)
                              : null
                          }
                          onChange={(date: any) => {
                            const formattedDate = date
                              ? date.format("YYYY-MM-DD")
                              : null;
                            formik.setFieldValue(
                              "booking_start",
                              formattedDate
                            );
                          }}
                          disabled={false}
                          placeholder="Select Start Date"
                          isCrossRequired={true}
                          disabledDate={(current) => {
                            const today = dayjs().startOf("day");
                            const availabilityStart = dayjs(
                              room?.booking_start
                            ).startOf("day");
                            const availabilityEnd = dayjs(
                              room?.booking_end
                            ).startOf("day");
                            // const bookedDates = room?.booked_dates || [];
                            // const isDateBooked = bookedDates.some((range: any) => {
                            //   if (!Array.isArray(range) || range.length !== 2) {
                            //       return false; // Skip invalid ranges
                            //     }

                            //     const [start, end] = range as [string, string]; // Type assertion
                            //     const startDate = dayjs(start).startOf("day");
                            //     const endDate = dayjs(end).startOf("day");
                            //     return (
                            //       current &&
                            //       current.isBetween(
                            //         startDate,
                            //         endDate,
                            //         "day",
                            //         "[]"
                            //       )
                            //     );
                            // });

                            return (
                              (current && current.isBefore(today)) ||
                              (current && current.isBefore(availabilityStart)) ||
                              (current && current.isAfter(availabilityEnd)) || false
                            );
                          }}
                          className="my-custom-class"
                        /> */}
                        {/* <CustomDatePickerV2
                          date={formik.values.booking_start}
                          onDateChange={(date) => {
                            const formattedDate = date
                              ? format(date, "yyyy-MM-dd")
                              : null;
                            formik.setFieldValue(
                              "booking_start",
                              formattedDate
                            );
                          }}
                          placeholder="Select Start Date"
                          disabledDates={[]}
                          minDate={
                            new Date(
                              room?.booking_start ??
                                propertyData?.availability_start
                            ) > new Date()
                              ? new Date(
                                  room?.booking_start ??
                                    propertyData?.availability_start
                                )
                              : new Date()
                          }
                          maxDate={
                            new Date(
                              room?.booking_end ??
                                propertyData?.availability_end
                            )
                          }
                          isCrossRequired={true}
                        /> */}
                        <CustomDatePickerV2
                          value={
                            selectedRoom === room.id
                              ? formik.values.booking_start || ""
                              : ""
                          }
                          onChange={(value) => {
                            formik.setFieldValue("room_id", room.id);
                            formik.setFieldValue(
                              "price_per_month",
                              room.price_per_month
                            );
                            formik.setFieldValue(
                              "booking_end",
                              selectedRoom !== room.id && null
                            );
                            formik.setFieldValue(
                              "number_of_guests",
                              selectedRoom !== room.id && null
                            );

                            const formattedDate = value
                              ? format(value, "yyyy-MM-dd")
                              : null;
                            formik.setFieldValue(
                              "booking_start",
                              formattedDate
                            );
                          }}
                          placeholder="Select Start Date"
                          minDate={
                            new Date(
                              room?.booking_start ??
                                propertyData?.availability_start
                            ) > new Date()
                              ? new Date(
                                  room?.booking_start ??
                                    propertyData?.availability_start
                                )
                              : new Date()
                          }
                          maxDate={
                            new Date(
                              room?.booking_end ??
                                propertyData?.availability_end
                            )
                          }
                          disabledDays={[]}
                          isCrossRequired={true}
                        />
                      </FormItem>

                      <FormItem label="Availability End Date" className="mt-4">
                        {/* <CustomDatePickerV2
                          value={
                            formik.values.booking_end
                              ? dayjs(formik.values.booking_end)
                              : null
                          }
                          onChange={(date: any) => {
                            const formattedDate = date
                              ? date.format("YYYY-MM-DD")
                              : null;
                            formik.setFieldValue("booking_end", formattedDate);
                          }}
                          placeholder="Select End Date"
                          isCrossRequired={true}
                          // disabledDate={(current) => {
                          //   const checkInDate = dayjs(
                          //     room?.booking_start
                          //   ).startOf("day");
                          //   const availabilityEnd = dayjs(
                          //     room?.booking_end
                          //   ).startOf("day");
                          //   const minimumMonths =
                          //     propertyData?.minimum_months_of_booking || 0;
                          //   const bookedDates = room?.booked_dates || [];

                          //   const minCheckOutDate = checkInDate.add(
                          //     minimumMonths,
                          //     "month"
                          //   );

                          //   const isDateBooked = bookedDates.some((range) => {
                          //     if (!Array.isArray(range) || range.length !== 2) {
                          //       return false; // Skip invalid ranges
                          //     }

                          //     const [start, end] = range as [string, string]; // Type assertion
                          //     const startDate = dayjs(start).startOf("day");
                          //     const endDate = dayjs(end).startOf("day");
                          //     return (
                          //       current &&
                          //       current.isBetween(
                          //         startDate,
                          //         endDate,
                          //         "day",
                          //         "[]"
                          //       )
                          //     ); // Inclusive range
                          //   });

                          //   return (
                          //     (current && current.isBefore(minCheckOutDate)) ||
                          //     (current && current.isAfter(availabilityEnd)) ||
                          //     isDateBooked
                          //   );
                          // }}
                          disabledDate={(current) => {
                            if (!current) return true;
                            const today = dayjs().startOf("day");
                            // const checkInDate = dayjs(
                            //   formik.values.booking_start
                            // ).startOf("day");
                            const availabilityStart = dayjs(
                              room?.booking_start ??
                                propertyData?.availability_start
                            ).startOf("day");
                            const availabilityEnd = dayjs(
                              room?.booking_end ??
                                propertyData?.availability_end
                            ).startOf("day");
                            // const minimumMonths =
                            //   propertyData?.minimum_months_of_booking || 0;
                            // const bookedDates = room?.booked_dates || [];

                            // const minCheckOutDate = checkInDate.add(minimumMonths, "month").startOf("day");

                            // const isDateBooked = bookedDates.some(([start, end]: [string, string]) => {
                            //     const startDate = dayjs(start).startOf("day");
                            //     const endDate = dayjs(end).startOf("day");
                            //   return current.isBetween(startDate, endDate, "day", "[]");
                            // });

                            // Check for dates before the first booked date
                            // const violatesMinimumMonthsBeforeBooked = bookedDates.some(([start]: [string, string]) => {
                            //     const startDate = dayjs(start).startOf("day");
                            //   const minDateBeforeBooked = startDate.subtract(minimumMonths, "month").startOf("day");
                            //     return current.isBefore(minDateBeforeBooked);
                            //   });

                            // Check for dates after the last booked date
                            // const violatesMinimumMonthsAfterBooked = bookedDates.some(([_, end]: [string, string]) => {
                            //     const endDate = dayjs(end).startOf("day");
                            //   const minDateAfterBooked = endDate.add(minimumMonths, "month").startOf("day");
                            //     return current.isAfter(minDateAfterBooked);
                            //   });

                            // Explicitly disable availability_end if it doesn't satisfy the minimum month criteria
                            // const violatesMinimumMonthsAtEnd = current.isSame(availabilityEnd, "day") &&
                            //   availabilityEnd.subtract(minimumMonths, "month").isBefore(current);

                            return (
                              current.isBefore(today) ||
                              current.isBefore(availabilityStart) ||
                              current.isAfter(availabilityEnd)
                            );
                          }}
                        /> */}

                        {/* <CustomDatePickerV2
                          date={formik.values.booking_end}
                          onDateChange={(date) => {
                            const formattedDate = date
                              ? format(date, "yyyy-MM-dd")
                              : null;
                            formik.setFieldValue("booking_end", formattedDate);
                          }}
                          placeholder="Select End Date"
                          disabledDates={[]}
                          minDate={
                            new Date(
                              room?.booking_start ??
                                propertyData?.availability_start
                            ) > new Date()
                              ? new Date(
                                  room?.booking_start ??
                                    propertyData?.availability_start
                                )
                              : new Date()
                          }
                          maxDate={
                            new Date(
                              room?.booking_end ??
                                propertyData?.availability_end
                            )
                          }
                          isCrossRequired={true}
                        /> */}

                        <CustomDatePickerV2
                          value={
                            selectedRoom === room.id
                              ? formik.values.booking_end || ""
                              : ""
                          }
                          onChange={(value) => {
                            formik.setFieldValue("room_id", room.id);
                            formik.setFieldValue(
                              "price_per_month",
                              room.price_per_month
                            );
                            formik.setFieldValue(
                              "booking_end",
                              selectedRoom !== room.id && null
                            );
                            formik.setFieldValue(
                              "number_of_guests",
                              selectedRoom !== room.id && null
                            );

                            const formattedDate = value
                              ? format(value, "yyyy-MM-dd")
                              : null;
                            formik.setFieldValue("booking_end", formattedDate);
                          }}
                          placeholder="Select End Date"
                          minDate={
                            new Date(
                              room?.booking_start ??
                                propertyData?.availability_start
                            ) > new Date()
                              ? new Date(
                                  room?.booking_start ??
                                    propertyData?.availability_start
                                )
                              : new Date()
                          }
                          maxDate={
                            new Date(
                              room?.booking_end ??
                                propertyData?.availability_end
                            )
                          }
                          disabledDays={[]}
                          isCrossRequired={true}
                        />
                      </FormItem>
                      <FormItem label="Select Guest" className="mt-4">
                        <SelectGuests
                          className={"flex-1"}
                          room={room}
                          maxGuests={room?.max_guests}
                          formik={formik}
                        />
                      </FormItem>
                    </div>
                  </form>
                  <div className="flex flex-col space-y-4">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>Price Per Month</span>
                      <span>
                        {`${currencySymbol()} ${
                          Number(room?.price_per_month)?.toFixed(2) || 0
                        }`}
                        /month
                      </span>
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                      <span>Total</span>
                      <span>
                        {`${currencySymbol()} ${
                          Number(room?.price_per_month)?.toFixed(2) || 0
                        }`}
                        /month
                      </span>
                    </div>
                  </div>
                  <ButtonPrimary
                    disabled={
                      (selectedRoom === room.id ? isButtonDisabled : true) ||
                      room.status === "inactive"
                    }
                    onClick={formik.handleSubmit}
                    loading={isLoading}
                  >
                    Reserve
                  </ButtonPrimary>
                  <ButtonPrimary onClick={() => handleContactOwner(room.id)}>
                    Contact Owner
                  </ButtonPrimary>
                  {isModalOpen && (
                    <ContactOwnerModal
                      currentRoomId={currentRoomId}
                      isOpen={isModalOpen}
                      onClose={handleCloseModal}
                      onSend={handleCloseModal}
                      propertyData={propertyData}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const RenderSidebar = () => {
    dayjs.extend(isBetween);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const handleSubmit = async (values: any) => {
      setIsLoading(true);
      const postData = {
        booking: {
          number_of_guests: Number(values.number_of_guests),
          property_id: propertyId,
          check_in_date: values.check_in_date,
          check_out_date: values.check_out_date,
        },
      };

      try {
        const action = await dispatch(reserveBooking(postData as any));
        if (
          action?.type === "payment/reserveBooking/fulfilled" &&
          action.payload
        ) {
          successHandler(
            "Booking request sent. Please ! wait for owner's confirmation!!"
          );
        }
      } catch (error) {
        ErrorHandler("Failed to reserve booking. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    const formik = useFormik({
      initialValues: {
        number_of_guests: null,
        check_in_date: null,
        check_out_date: null,
        availability_start: null,
        availability_end: null,
        unlimited_availability: false,
        minimum_months_of_booking: 0,
        room_id: null,
      },
      enableReinitialize: true,
      onSubmit: (values, { setSubmitting, resetForm }) => {
        return handleSubmit(values);
      },
    });

    useEffect(() => {
      const { check_in_date, check_out_date, number_of_guests } = formik.values;
      if (check_in_date && check_out_date && number_of_guests) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }, [formik.values]);

    if (!propertyData) return null;

    return (
      <>
        <div className="listingSectionSidebar__wrap shadow-xl">
          {/* PRICE */}
          <div className="flex justify-between">
            <span className="text-3xl font-semibold text-black dark:text-neutral-400">
              {`${currencySymbol()} ${
                Number(propertyData?.price_per_month)?.toFixed(2) || 0
              }`}
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /month
              </span>
            </span>
            <StartRating />
          </div>

          <form onSubmit={formik.handleSubmit}>
            <FormItem label="Availability Start Date" className="mt-4">
              {/* <CustomDatePickerV2
                value={
                  formik.values.check_in_date
                    ? dayjs(formik.values.check_in_date)
                    : null
                }
                onChange={(date: any) => {
                  const formattedDate = date ? date.format("YYYY-MM-DD") : null;
                  formik.setFieldValue("check_in_date", formattedDate);
                }}
                disabled={false}
                placeholder="Select Start Date"
                isCrossRequired={true}
                disabledDate={(current) => {
                  const today = dayjs().startOf("day");
                  const availabilityStart = dayjs(
                    propertyData?.availability_start
                  ).startOf("day");
                  const availabilityEnd = dayjs(
                    propertyData?.availability_end
                  ).startOf("day");
                  // const bookedDates = propertyData?.booked_dates || [];

                  // Check if current date is within booked ranges
                  // const isDateBooked = bookedDates.some(
                  //   ([start, end]: [string, string]) => {
                  //     const startDate = dayjs(start).startOf("day");
                  //     const endDate = dayjs(end).startOf("day");
                  //     return (
                  //       current &&
                  //       current.isBetween(startDate, endDate, "day", "[]")
                  //     );
                  //   }
                  // );

                  return (
                    (current && current.isBefore(today)) ||
                    (current && current.isBefore(availabilityStart)) ||
                    (current && current.isAfter(availabilityEnd)) || false
                  );
                }}
                className="my-custom-class"
              /> */}
              {/* <CustomDatePickerV2
                date={formik.values.check_in_date}
                onDateChange={(date) => {
                  const formattedDate = date
                    ? format(date, "yyyy-MM-dd")
                    : null;
                  formik.setFieldValue("check_in_date", formattedDate);
                }}
                placeholder="Select Start Date"
                disabledDates={[]}
                minDate={
                  new Date(propertyData?.availability_start) > new Date()
                    ? new Date(propertyData?.availability_start)
                    : new Date()
                }
                maxDate={new Date(propertyData?.availability_end)}
                isCrossRequired={true}
              /> */}
              <CustomDatePickerV2
                value={formik.values.check_in_date || ""}
                onChange={(value) => {
                  const formattedDate = value
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "";
                  formik.setFieldValue("check_in_date", formattedDate); // Save formatted date
                }}
                placeholder="Select Start Date"
                minDate={
                  new Date(propertyData?.availability_start) > new Date()
                    ? new Date(propertyData?.availability_start)
                    : new Date()
                }
                maxDate={new Date(propertyData?.availability_end ?? "")}
                disabledDays={[]}
                isCrossRequired={true}
              />
            </FormItem>

            <FormItem label="Availability End Date" className="mt-4">
              {/* <CustomDatePickerV2
                value={
                  formik.values.check_out_date
                    ? dayjs(formik.values.check_out_date)
                    : null
                }
                onChange={(date: any) => {
                  const formattedDate = date ? date.format("YYYY-MM-DD") : null;
                  formik.setFieldValue("check_out_date", formattedDate);
                }}
                placeholder="Select End Date"
                isCrossRequired={true}
                disabledDate={(current) => {
                  if (!current) return true;
                  const today = dayjs().startOf("day");
                  // const checkInDate = dayjs(
                  //   formik.values.check_in_date
                  // ).startOf("day");
                  const availabilityStart = dayjs(
                    propertyData?.availability_start
                  ).startOf("day");
                  const availabilityEnd = dayjs(
                    propertyData?.availability_end
                  ).startOf("day");
                  // const minimumMonths =
                  //   propertyData?.minimum_months_of_booking || 0;
                  // const bookedDates = propertyData?.booked_dates || [];

                  // const minCheckOutDate = checkInDate.add(minimumMonths, "month").startOf("day");

                  // const isDateBooked = bookedDates.some(([start, end]: [string, string]) => {
                  //     const startDate = dayjs(start).startOf("day");
                  //     const endDate = dayjs(end).startOf("day");
                  //     return current.isBetween(startDate, endDate, "day", "[]");
                  // });

                  //Check for dates before the first booked date
                  // const violatesMinimumMonthsBeforeBooked = bookedDates.some(([start]: [string, string]) => {
                  //     const startDate = dayjs(start).startOf("day");
                  //   const minDateBeforeBooked = startDate.subtract(minimumMonths, "month").startOf("day");
                  //     return current.isBefore(minDateBeforeBooked);
                  // });

                  //Check for dates after the last booked date
                  // const violatesMinimumMonthsAfterBooked = bookedDates.some(([_, end]: [string, string]) => {
                  //     const endDate = dayjs(end).startOf("day");
                  //   const minDateAfterBooked = endDate.add(minimumMonths, "month").startOf("day");
                  //     return current.isAfter(minDateAfterBooked);
                  // });

                  //Explicitly disable availability_end if it doesn't satisfy the minimum month criteria
                  // const violatesMinimumMonthsAtEnd = current.isSame(availabilityEnd, "day") &&
                  //   availabilityEnd.subtract(minimumMonths, "month").isBefore(current);

                  return (
                    current.isBefore(today) ||
                    current.isBefore(availabilityStart) ||
                    current.isAfter(availabilityEnd)
                  );
                }}
              /> */}

              {/* <CustomDatePickerV2
                date={formik.values.check_out_date}
                onDateChange={(date) => {
                  const formattedDate = date
                    ? format(date, "yyyy-MM-dd")
                    : null;
                  formik.setFieldValue("check_out_date", formattedDate);
                }}
                disabledDates={[]}
                placeholder="Select End Date"
                minDate={
                  new Date(propertyData?.availability_start) > new Date()
                    ? new Date(propertyData?.availability_start)
                    : new Date()
                }
                maxDate={new Date(propertyData?.availability_end)}
                isCrossRequired={true}
              /> */}
              <CustomDatePickerV2
                value={formik.values.check_out_date || ""}
                onChange={(value) => {
                  const formattedDate = value
                    ? format(value, "yyyy-MM-dd")
                    : "";
                  formik.setFieldValue("check_out_date", formattedDate);
                }}
                placeholder="Select End Date"
                minDate={
                  new Date(propertyData?.availability_start) > new Date()
                    ? new Date(propertyData?.availability_start)
                    : new Date()
                }
                maxDate={new Date(propertyData?.availability_end ?? "")}
                disabledDays={[]}
              />
            </FormItem>
            <FormItem label="Select Guest" className="mt-4">
              <SelectGuests
                className={"flex-1"}
                room={{}}
                maxGuests={15}
                formik={formik}
              />
            </FormItem>

            {/* SUM */}
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300"></div>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between font-semibold">
                <span>Total</span>
                <span>
                  {`${currencySymbol()} ${
                    Number(
                      Number(propertyData?.price_per_month) +
                        propertyData?.property_taxes.reduce(
                          (acc, tax) => acc + tax.value,
                          0
                        )
                    )?.toFixed(2) || 0
                  }`}
                </span>
              </div>
            </div>
            <div className="w-full flex flex-col space-y-4 mt-5">
              <ButtonPrimary
                // disabled={!endDate}
                disabled={isButtonDisabled}
                onClick={() => handleSubmit(formik.values)}
                loading={isLoading}
              >
                Reserve
              </ButtonPrimary>
              <ButtonPrimary onClick={handleChatButtonClick}>
                Contact Owner
              </ButtonPrimary>
            </div>
          </form>
          {isModalOpen && (
            <ContactOwnerModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onSend={handleSendMessage}
              propertyData={propertyData}
            />
          )}
        </div>
      </>
    );
  };

  if (!propertyData) return null;
  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          {/* Main Image */}
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >
            <NcImage
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={propertyData.place_images[0]?.url}
              alt="Property Image"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>

          {/* Thumbnail Images */}
          {propertyData.place_images
            .filter((_, i) => i >= 1 && i < 5)
            .map((item, index) => (
              <div
                key={item.id}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                  <NcImage
                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                    src={item.url || ""}
                    alt={`Property Thumbnail ${index + 1}`}
                    sizes="400px"
                  />
                </div>

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={handleOpenModalImageGallery}
                />
              </div>
            ))}

          <button
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </button>
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        <div
          className={`space-y-8 lg:space-y-10 ${
            propertyData?.is_shared_property
              ? ""
              : " w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10"
          }`}
        >
          {renderSection1()}
          <RenderSection2 />
          {renderSection3()}
          {/* {renderSection4()}  */}
          {!propertyData?.is_shared_property && (
            <div className="listingSection__wrap w-full">
              <SectionDateRange
                availabilityStart={new Date(
                  propertyData?.availability_start || Date.now()
                ).getTime()}
                availabilityEnd={new Date(
                  propertyData?.availability_end || Date.now()
                ).getTime()}
              />
            </div>
          )}
          {renderSection5()}
          {renderSection7()}
          {renderSection8()}
          <RenderSection9 />
          {renderSection6()}
        </div>
        <div className=" lg:block flex-grow mt-14 lg:mt-0">
          {!propertyData?.is_shared_property && (
            <div className="sticky top-28">
              <RenderSidebar />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
