import { AppDispatch, RootState } from "../../redux/store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  deleteUserAccount,
} from "../../redux/reducers/UserSlice/UserSlice";
import successHandler from "../../utils/helpers/SuccessHandler";
import Avatar from "shared/Avatar/Avatar";
import { killSession} from "../../redux/reducers/AuthSlice/AuthSlice";
import { useNavigate } from "react-router-dom";

const DeleteAccount = () => {
  const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
  const { currentUser } = useSelector(
    (state: RootState) => state.user
  );

  const handleDeleteAccount = () => {
    const confrimation = window.confirm(
      "Are you sure you want to delete your account?"
    );
    if (confrimation) {
      console.log("currentUsercurrentUser", currentUser);
      dispatch(deleteUserAccount(currentUser?.id))
        .unwrap()
        .then((response) => {
          if (response) {
            successHandler("User deleted successfully");
            setTimeout(() => {
               dispatch(killSession());
                navigate("/");
            }, 3000);
          }
        })
        .catch((error: any) => {
          console.error("Error deleting user profile: ", error);
        });
    }
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex-shrink-0 flex items-start">
        <div className="relative rounded-full overflow-hidden flex">
          <Avatar
            sizeClass="w-32 h-32"
            containerClassName="hidden md:block invisible"
          />
        </div>
      </div>
      <div className="flex-grow mt-10 md:mt-0 md:ml-8 space-y-6">
        <h2 className="text-3xl font-semibold mt-6 mb-4">Delete Account</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mt-6 mb-6"></div>
        <div className="">
          <p className="text-sm text-gray-600 mb-4">
            Are you sure you want to delete your account?
          </p>
          <div className="flex justify-start">
            <ButtonPrimary
              type="submit"
              // disabled={!currentUser?.email_verified}
              className="mt-5"
              onClick={handleDeleteAccount}
              // loading={formik.isSubmitting}
            >
              Delete Account
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
