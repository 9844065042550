import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../../utils/helpers/APIHelper";
import { RootState } from "../../store";
// import { SignInRequestPayload, SignUpRequestPayload } from "./AuthSliceTypes";
import ErrorHandler from "../../../utils/helpers/ErrorHandler";
import { ENDPOINTS } from "../../../API/apiConstants";
import { PropertiesState } from "./PropertiesSliceTypes";
import { toast } from "react-toastify";

interface InvoicePayload {
  bookingId?: number;
  booking: {
    status: string;
    invoice_attributes: {
      status: string;
      billing_type: string;
      invoice_type: string;
      expenses_attributes: Expenses[];
      discounts_attributes: Discount[];
    };
  };
}

interface Expenses {
  category: string;
  amount: number;
}

interface Discount {
  description: string;
  amount: number;
}

const initialState: PropertiesState = {
  loading: false,
  allProperties: [],
  propertiesCategories: [],
  amenitiesCategories: [],
  houseRule: [],
  globalId: null,
  propertyToCreate: null,
  PropertyToEdit: null,
  details: null,
  propertyTypes: [],
  property: [],
  featuresCategories: [],
  allTex: [],
  roomamenitiesCategories: [],
  roomfeaturesCategories: [],
  bedType: [],
  roomType: [],
  bookings: [],
  metaData: {},
  Chat: {},
  booking: {},
  query: {},
  queryData: {},
  cancelTypes: [],
};

export const getAllProperties = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  any,
  { state: RootState; rejectValue: any }
>("properties/allProperties", async (page, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.GET_ALL_PROPERTIES(page));
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const searchProperties = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any }
>("properties/searchProperties", async (searchQuery, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.SEARCH_PROPERTIES(searchQuery));
    return response;
  } catch (error:any) {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.error || error.response.data.message, {
        position: "top-right",
      });
      return error;
    }
    // ErrorHandler(error);
    return error;
  }
});

export const getPropertyCategories = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("properties/propertiesCategories", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.PROPERTY_CATEGORY);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getPropertyType = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("properties/propertyTypes ", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.GET_ALL_PROPERTY_TYPE);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const createProperties = createAsyncThunk<
  any, // Payload type for successful response
  any, // Payload type for the request body
  { state: RootState; rejectValue: any }
>("stay/createProperties", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.post(ENDPOINTS.CREATE_PROPRTIES, payload);
    // Ensure the correct shape of the response is returned
    return response; // Assuming the data you need is inside 'data'
  } catch (error) {
    ErrorHandler(error); // Handle error with your custom error handler
    return rejectWithValue(error); // Return error with rejectWithValue
  }
});

export const updateProperties = createAsyncThunk<
  any, // Payload type for successful response
  any,
  { state: RootState; rejectValue: any }
>("stay/updateProperties", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.put(
      `${ENDPOINTS.UPDATE_PROPERTIES}/${payload?.property?.id}`,
      payload
    );
    // Ensure the correct shape of the response is returned
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const uploadImageProperties = createAsyncThunk<
  any, // Payload type for successful response
  FormData, // Accept FormData as the payload type
  { state: RootState; rejectValue: any }
>("property/uploadImageProperties", async (formData, { rejectWithValue }) => {
  try {
    let propertyId = formData.get("property[id]");

    // If 'id' is missing, append it (you can replace globalId or id as per your logic)
    if (!propertyId) {
      propertyId = formData.get("id"); // Assuming 'id' might be present directly in FormData
    }

    if (!propertyId) {
      return rejectWithValue("Property ID is required");
    }

    // Send the FormData with the propertyId appended dynamically
    const response = await API.put(
      `${ENDPOINTS.UPDATE_PROPERTIES}/${propertyId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    // Return the response data
    return response;
  } catch (error) {
    // Handle error using the ErrorHandler
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getallupdateProperties = createAsyncThunk<
  any, // Payload type for successful response
  any,
  { state: RootState; rejectValue: any }
>("stay/PropertyToEdit", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.get(
      `${ENDPOINTS.GET_ALL_UPDATE_PROPERTY_DETAILS}/${payload?.propertyId}`,
      payload
    );
    // Ensure the correct shape of the response is returned
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getallPropertieslisting = createAsyncThunk<
  any, // Payload type for successful response
  any,
  { state: RootState; rejectValue: any }
>("stay/PropertyToEdit", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.get(
      `${ENDPOINTS.GET_PROPERTY_Listing(payload.page, payload.query)}`
    );
    // Ensure the correct shape of the response is returned
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const updatePropertiesListingStatus = createAsyncThunk<
  any, // Payload type for successful response
  any,
  { state: RootState; rejectValue: any }
>("stay/updateProperties", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.put(
      `${ENDPOINTS.UPDATE_PROPERTY_LISTING}/${payload?.property?.id}`,
      payload
    );
    // Ensure the correct shape of the response is returned
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const updateImagesProperties = createAsyncThunk<
  any, // Payload type for successful response
  any, // Expecting the property to be of type FormData
  { state: RootState; rejectValue: any }
>("stay/updateProperties", async (formData: FormData, { rejectWithValue }) => {
  try {
    // Ensure the property has the correct ID in the payload
    const id = formData.get("property[id]"); // Use get('property[id]') to retrieve the ID
    const response = await API.put(
      `${ENDPOINTS.UPDATE_PROPERTIES}/${id}`, // Use the retrieved ID in the URL
      formData // Sending the FormData object
    );
    // Ensure the correct shape of the response is returned
    return response; // Assuming the actual response data is in response.data
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getAmenitiesProperty = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("properties/amenitiesProperty", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.AMENITIES_PROPERTIES);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getRoomAmenitiesProperty = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("properties/roomamenitiesCategories", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.ROOM_AMENITIES_PROPERTIES);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getFeaturesProperty = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("properties/featuresCategories", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.GET_ALL_FEATURES_STATUS);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getRoomFeaturesProperty = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("properties/roomfeaturesCategories", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.ROOM_FEATURES_PROPERTIES);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getHouseRule = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("properties/houseRule", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.HOUSE_RULE);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getBookings = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  any,
  { state: RootState; rejectValue: any }
>("properties/bookings", async (page, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.BOOKING_DEATAILS(page));
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getRequestBookings = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  any,
  { state: RootState; rejectValue: any }
>("properties/bookings", async (page, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.MANAGE_REQUEST_BOOKING(page));
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getBookingsDetails = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  any,
  { state: RootState; rejectValue: any }
>("properties/bookings", async (id, { rejectWithValue }) => {
  try {
    const response = await API.get(`${ENDPOINTS.GET_BOOKING_DEATAILS}/${id}`);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getUserBookingsDetails = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  any,
  { state: RootState; rejectValue: any }
>("properties/bookings", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.GET_BOOKING_DEATAILS);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getHostBookingsDetails = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  any,
  { state: RootState; rejectValue: any }
>("properties/bookings", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.HOST_BOOKING_DETAILS);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getBookingsQueryDetails = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  any,
  { state: RootState; rejectValue: any }
>("properties/bookings", async (id, { rejectWithValue }) => {
  try {
    const response = await API.get(
      `${ENDPOINTS.GET_BOOKING_QUERIES_DATA}/${id}`
    );
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getBookingsQuery = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  number,
  { state: RootState; rejectValue: any }
>("properties/queryData", async (page, { rejectWithValue }) => {
  try {
    const response = await API.get(`${ENDPOINTS.MANAGE_QUERIES_BOOKING(page)}`);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});
export const getCancellation = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("properties/cancelTypes", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.GET_CANCILATION_DETAILS);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const updateBookingsQueryDetails = createAsyncThunk<
  any,
  {
    id: number;
    propertyId: number;
    state: string;
    check_in_date?: string;
    check_out_date?: string;
  },
  { state: RootState; rejectValue: any }
>(
  "properties/query",
  async (
    { id, propertyId, state, check_in_date, check_out_date },
    { rejectWithValue }
  ) => {
    try {
      // Build the payload dynamically, including optional properties like check_in_date and check_out_date
      const payload: any = { booking_query: { state } };

      if (check_in_date) {
        payload.booking_query.check_in_date = check_in_date;
      }

      if (check_out_date) {
        payload.booking_query.check_out_date = check_out_date;
      }

      const response = await API.put(
        ENDPOINTS.BOOKING_QUERY(id, propertyId),
        payload
      );
      return response;
    } catch (error: any) {
      ErrorHandler(error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const sendBookingInvoice = createAsyncThunk<
  any,
  InvoicePayload,
  { state: RootState; rejectValue: any }
>("properties/sendBookingInvoice", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.put(
      `${ENDPOINTS.GET_BOOKING_DEATAILS}/${payload.bookingId}`,
      payload
    );
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getGetTax = createAsyncThunk<
  any,
  void,
  { state: RootState; rejectValue: any }
>("properties/allTex", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.GET_TAX);
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getChatBooking = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any }
>("properties/Chat", async ({chatId, page}, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.GET_MESSAGE(chatId,page));
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const postChatBooking = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any }
>("properties/Chat", async ({ chatId, page, formData }, { rejectWithValue }) => {
  try {
    const response = await API.post(ENDPOINTS.GET_MESSAGE(chatId,page), formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getBedType = createAsyncThunk<
  any,
  void,
  { state: RootState; rejectValue: any }
>("properties/roomType", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.ROOM_BED_TYPE);
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const bookingQuery = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any }
>("properties/booking", async (page, { rejectWithValue }) => {
  try {
    const response = await API.get(`${ENDPOINTS.GET_BOOKING_QUERIES(page)}`);
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const deleteInvoice = createAsyncThunk<
  any, // Optionally replace `any` with a stricter type if available
  {
    bookingId: string | number;
    id?: number;
    _destroy: boolean;
    status: string;
  },
  { state: RootState; rejectValue: any }
>(
  "properties/bookings",
  async ({ bookingId, id, _destroy, status }, { rejectWithValue }) => {
    try {
      const response = await API.put(
        `${ENDPOINTS.GET_BOOKING_DEATAILS}/${bookingId}`,
        {
          booking: {
            status, // Dynamically setting status here
            invoice_attributes: {
              id,
              _destroy,
            },
          },
        }
      );
      return response.data;
    } catch (error) {
      ErrorHandler(error);
      return rejectWithValue(error);
    }
  }
);

export const deleteImage = createAsyncThunk<
  any,
  { id: number },
  { state: RootState; rejectValue: any }
>("properties/deleteImage", async ({ id }, { rejectWithValue }) => {
  try {
    const response = await API.delete(ENDPOINTS.DELETEIMAGE(id)); // Passing the id into the endpoint
    return response.data;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getRoomType = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("properties/bedType", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.ROOM_TYPE);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    setId: (state, action) => {
      state.globalId = action.payload;
    },
    setPropertyToCreate: (state, action) => {
      state.propertyToCreate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllProperties.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.allProperties = data?.properties;
        state.metaData = data?.meta;
        state.loading = false;
      }
    );
    builder.addCase(
      getGetTax.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.allTex = data?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      getBookingsQuery.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.queryData = data?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      getBedType.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.bedType = data?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      getChatBooking.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.Chat = data?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      getRoomType.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.bedType = data?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      getPropertyCategories.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.propertiesCategories = data?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      bookingQuery.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.booking = data?.data;
        state.loading = false;
      }
    );

    builder.addCase(
      getFeaturesProperty.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.featuresCategories = data?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      getRoomFeaturesProperty.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.roomfeaturesCategories = data?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      getPropertyType.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.propertyTypes = data?.data;
        state.loading = false;
      }
    );

    builder.addCase(
      getCancellation.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.cancelTypes = data?.data;
        state.loading = false;
      }
    );

    builder.addCase(
      getAmenitiesProperty.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.amenitiesCategories = data?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      getRoomAmenitiesProperty.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.roomamenitiesCategories = data?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      getHouseRule.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.houseRule = data?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      getBookings.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.metaData = data?.meta;
        state.bookings = data?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      getallupdateProperties.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.PropertyToEdit = data?.property;
        state.loading = false;
      }
    );
    builder.addMatcher(
      (action) =>
        [
          getAllProperties.pending.type,
          getPropertyCategories.pending.type,
          getPropertyType.pending.type,
        ].includes(action.type),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      (action) =>
        [
          getAllProperties.rejected.type,
          getPropertyCategories.rejected.type,
          getPropertyType.rejected.type,
          getallupdateProperties.rejected.type,
        ].includes(action.type),
      (state) => {
        state.loading = false;
        state.PropertyToEdit = null;
      }
    );
  },
});

export const { setId, setPropertyToCreate } = propertiesSlice.actions;

export default propertiesSlice.reducer;
