import React, { useState } from "react";
import { Modal } from "react-rainbow-components";
import { format } from "date-fns";
import CustomDatePickerV2 from "./CustomDatePickerV2";

// Update the interface to match your DateRangePicker type
interface DateRangePicker {
  startDate: any;
  endDate: any;
}

interface DateSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (dates: any) => any;
  bookingRequestDetails: any;
}

const DateSelectionModal: React.FC<DateSelectionModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  bookingRequestDetails,
}) => {
  const propertyCheckIn =
    bookingRequestDetails?.booking_query?.property?.availability_start;
  const propertyCheckOut =
    bookingRequestDetails?.booking_query?.property?.availability_end;

  const [dates, setDates] = useState<DateRangePicker>({
    startDate: null,
    endDate: null,
  });

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(dates);
      setDates({...dates, startDate: '', endDate: ''})
    }
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Modal id="modal-1" isOpen={isOpen} onRequestClose={onClose}>
      <div className="mb-5">
        <h3
          className="text-lg font-medium leading-6 text-gray-900"
          id="modal-title"
        >
          Change Requested Dates
        </h3>
      </div>
      <div className="grid gap-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">
              Check In
            </label>
            <div className="relative">
              <CustomDatePickerV2
                value={dates.startDate || ""}
                onChange={(value) => {
                  const formattedDate = value
                    ? format(value, "yyyy-MM-dd")
                    : null;
                  setDates((prev) => ({
                    ...prev,
                    startDate: formattedDate,
                  }));
                }}
                placeholder={`MMM DD, YYYY`}
                minDate={new Date(propertyCheckIn)}
                maxDate={new Date(propertyCheckOut)}
                disabledDays={[]}
                isCrossRequired={true}
              />
            </div>
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">
              Check Out
            </label>
            <div className="relative">
              <CustomDatePickerV2
                value={dates.endDate || ""}
                onChange={(value) => {
                  const formattedDate = value
                    ? format(value, "yyyy-MM-dd")
                    : null;
                  setDates((prev) => ({
                    ...prev,
                    endDate: formattedDate,
                  }));
                }}
                placeholder={`MMM DD, YYYY`}
                minDate={new Date(propertyCheckIn)}
                maxDate={new Date(propertyCheckOut)}
                disabledDays={[]}
                isCrossRequired={true}
              />
            </div>
          </div>
        </div>

        <button
          disabled={!dates.startDate || !dates.endDate}
          onClick={handleSubmit}
          className="w-full px-4 py-2 mt-4 text-white bg-red-400 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          Change Requested Dates
        </button>
      </div>
    </Modal>
  );
};

export default DateSelectionModal;
