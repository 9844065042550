import { MapPinIcon } from "@heroicons/react/24/solid";
import { FC, useEffect, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import FormItem from "./FormItem";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "redux/store";
import { useFormik } from "formik";
import {
  createProperties,
  getallupdateProperties,
  setId,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import { locationValidationSchema } from "utils/formSchema";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useSelector } from "react-redux";
import __countryListing from "../../data/jsons/__countryListing.json";
import CommonLayoutV2 from "./CommonLayoutV2";

export interface Location {}

interface FormValues {
  title: string;
  country: string;
  street: string;
  city: string;
  state: string;
  zipcode: string | number;
  latitude: number | null;
  longitude: number | null;
}

const Location: FC<Location> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { globalId, propertyToCreate } = useSelector(
    (state: RootState) => state.property
  );
  const { slug } = useParams();
  const id = slug;
  const RouteID = globalId || id;
  const [loading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<any>(null);
  const [propertyToEdit, setPropertyToEdit] = useState<any>(null);
  const [userLocation, setUserLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updateLocation, setUpdateLocation] = useState(false);

  useEffect(() => {
    const fetchProperty = async () => {
      const propertyId = id;
      if (propertyId) {
        const action = await dispatch(getallupdateProperties({ propertyId }));
        if (action?.payload?.success) {
          setPropertyToEdit(action?.payload?.property);
        }
      }
    };
    fetchProperty();
  }, [dispatch, globalId, id]);

  const initialValues = {
    title: propertyToEdit?.title || "",
    country: propertyToEdit?.country || "",
    street: address?.label || propertyToEdit?.address || "",
    city: address?.city || propertyToEdit?.city || "",
    state: address?.state || propertyToEdit?.state || "",
    zipcode: address?.postalCode || propertyToEdit?.zipcode || "",
    latitude: userLocation?.latitude || propertyToEdit?.latitude || null,
    longitude: userLocation?.longitude || propertyToEdit?.longitude || null,
  };

  const formik = useFormik<FormValues>({
    initialValues: initialValues,
    validationSchema: locationValidationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload = {
        address: `${values.street}`,
        city: values.city,
        state: values.state,
        country: values.country,
        zipcode: values.zipcode,
        latitude: values.latitude,
        longitude: values.longitude,
        ...(id ? { id: id } : {}),
      };

      setSubmitting(true);
      if (id) {
        if (isEditing) {
          dispatch(updateProperties({ propertyId: id, property: payload }))
            .unwrap()
            .then((response) => {
              if (response?.success) {
                successHandler(response?.message || "Updated successfully");
                navigate(`/add-listing-6/${slug}`);
                resetForm();
              } else {
                console.log("Update failed: ", response);
              }
            })
            .catch((error) => {
              console.log("Error: ", error);
            })
            .finally(() => {
              setSubmitting(false);
            });
        } else {
          navigate(`/add-listing-6/${slug}`);
        }
      } else {
        dispatch(createProperties({ property: payload }))
          .unwrap()
          .then((response) => {
            if (response?.success) {
              successHandler(response?.message || "Created successfully");
              dispatch(setId(response.property.id));
              navigate(`/add-listing-6?id=${response.property.id}`);
              resetForm();
            } else {
              console.log("Creation failed: ", response);
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
  });

  const initialFormValues = JSON.stringify(initialValues);

  useEffect(() => {
    const currentFormValues = JSON.stringify(formik.values);
    if (updateLocation) {
      setIsEditing(true);
    } else {
      setIsEditing(currentFormValues !== initialFormValues);
    }
  }, [formik.values, initialFormValues, updateLocation]);

  const fetchAddress = async (latitude: number, longitude: number) => {
    const geocodeUrl = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${latitude}%2C${longitude}&lang=en-US&apiKey=${process.env.REACT_APP_LOCATION_KEY}`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();
      const address = data?.items[0]?.address;
      if (address) {
        setAddress(address);
      } else {
        console.error("No address found");
      }
    } catch (error) {
      console.error("Error fetching geocode data:", error);
    }
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUpdateLocation(true);
          setUserLocation({ latitude, longitude });
          fetchAddress(latitude, longitude);
          setLoading(false);
        },

        (error) => {
          console.error("Error get user location: ", error);
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
      console.log("Geolocation is not supported by this browser");
    }
  };

  return (
    <CommonLayoutV2 currentHref="/add-listing-5" PropertyID={RouteID}>
      <>
        <h2 className="text-2xl font-semibold">Listing Location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* FORM */}
        <form onSubmit={formik.handleSubmit}>
          <div className="space-y-8">
            <ButtonSecondary type="button" onClick={getUserLocation}>
              {loading ? (
                "fetching ..."
              ) : (
                <>
                  <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                  <span className="ml-3">Use current location</span>
                </>
              )}
            </ButtonSecondary>
            <FormItem label="Address">
              <Input
                name="street"
                value={formik.values.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter Address"
              />
              {formik.touched.street && formik.errors.street ? (
                <div className="text-red-600">{formik.errors.street}</div>
              ) : null}
            </FormItem>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
              <FormItem label="City">
                <Input
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.city && formik.errors.city ? (
                  <div className="text-red-600">{formik.errors.city}</div>
                ) : null}
              </FormItem>

              <FormItem label="State">
                <Input
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.state && formik.errors.state ? (
                  <div className="text-red-600">{formik.errors.state}</div>
                ) : null}
              </FormItem>

              <FormItem label="Zipcode">
                <Input
                  name="zipcode"
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.zipcode && formik.errors.zipcode ? (
                  <div className="text-red-600">{formik.errors.zipcode}</div>
                ) : null}
              </FormItem>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
              <FormItem label="Latitude">
                <Input
                  name="latitude"
                  value={formik.values.latitude || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.latitude && formik.errors.latitude ? (
                  <div className="text-red-600">{formik.errors.latitude}</div>
                ) : null}
              </FormItem>

              <FormItem label="Longitude">
                <Input
                  name="longitude"
                  value={formik.values.longitude || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.longitude && formik.errors.longitude ? (
                  <div className="text-red-600">{formik.errors.longitude}</div>
                ) : null}
              </FormItem>
            </div>

            <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
              <div className="rounded-xl overflow-hidden z-0">
                <iframe
                  title="x"
                  width="100%"
                  height="100%"
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps?q=${
                    formik?.values?.latitude
                  },${formik?.values?.longitude}&z=${15}&output=embed`}
                />
              </div>
            </div>

            {/* Submit buttons */}
            <div className="flex justify-end space-x-5">
              <ButtonSecondary href={`/add-listing-4/${slug}`}>
                Go back
              </ButtonSecondary>
              <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
                {formik.isSubmitting ? "Loading..." : "Continue"}
              </ButtonPrimary>
            </div>
          </div>
        </form>
      </>
    </CommonLayoutV2>
  );
};

export default Location;
