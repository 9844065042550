import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { signIn } from "../../redux/reducers/AuthSlice/AuthSlice";
import { getCurrentUser } from "../../redux/reducers/UserSlice/UserSlice";
import SocialAuth from "../../components/SocialAuth/SocialAuth";
import successHandler from "../../utils/helpers/SuccessHandler";
import { LoginSchema } from "../../utils/formSchema";
import FormField from "../../shared/FormField/FormField";

const PageLogin = ({ className = "" }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload = {
        email: values.email,
        password: values.password,
      };

      setSubmitting(true);
      dispatch(signIn(payload))
        .unwrap()
        .then((response) => {
          if (response?.success) {
            localStorage.setItem("authToken", response?.access_token);
            successHandler(response?.message || "Logged in successfully");
            dispatch(getCurrentUser())
              .unwrap()
              .then((res) => {
                if (res?.success) {
                  if (res?.data?.email_verified) {
                    navigate("/");
                  } else {
                    navigate("/account");
                  }
                }
              });
            resetForm();
          } else {
            console.log("Login failed: ", response);
          }
        })
        .catch((error) => {
          console.log("Error in login: ", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>

        <div className="max-w-md mx-auto space-y-6">
          <SocialAuth />
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form
            onSubmit={formik.handleSubmit}
            className="grid grid-cols-1 gap-6"
          >
            <FormField
              label="Email address"
              name="email"
              type="email"
              placeholder="example@example.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email}
            />

            <FormField
              label="Password"
              placeholder="******"
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && formik.errors.password}
            />

            <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
              {formik.isSubmitting ? "Submitting..." : "Continue"}
            </ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <Link to="/edit_password" className="text-sm underline">
              Forgot password?
            </Link>
          </span>
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup" className="text-orange-500">
              Create an account
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
