import React, { FC, Fragment, useEffect, useState, useCallback } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import convertNumbThousand from "utils/convertNumbThousand";
import { debounce } from "lodash";
import {
  getPropertyCategories,
  getPropertyType,
  getAmenitiesProperty,
  getFeaturesProperty,
  searchProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import LocationInput from "../../components/HeroSearchForm/LocationInput";
import GuestsInput from "../../components/HeroSearchForm/GuestsInput";
import StayDatesRangeInput from "../../components/HeroSearchForm/(stay-search-form)/StayDatesRangeInput";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormItem from "containers/PageAddListing1/FormItem";
import FormField from "shared/FormField/FormField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { useNavigate } from "react-router-dom";

export interface TabFiltersProps {
  startDate?: any;
  endDate?: any;
}

const TabFilters: FC<TabFiltersProps> = ({ startDate, endDate }) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    propertiesCategories,
    propertyTypes,
    amenitiesCategories,
    featuresCategories,
  } = useSelector((state: RootState) => state.property);

  const [filteredPropertyTypes, setFilteredPropertyTypes] = useState<any[]>([]);
  const [selectedAmenities, setSelectedAmenities] = useState<any[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<any[]>([]);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 1216]);
  const [searchResults, setSearchResults] = useState([]);
  const [isNoResults, setIsNoResults] = useState(false);
  const navigate = useNavigate();
  const [address, setAddress] = useState<any>(null);
  const [locationSuggestions, setLocationSuggestions] = useState<string[]>([]);
  const [userLocation, setUserLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  useEffect(() => {
    if (!address) {
      getUserLocation();
    }
  }, []);

  useEffect(() => {
    dispatch(getPropertyCategories());
    dispatch(getPropertyType());
    dispatch(getAmenitiesProperty());
    dispatch(getFeaturesProperty());
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(propertyTypes)) {
      setFilteredPropertyTypes(propertyTypes);
    }
  }, [propertyTypes]);

  const formik = useFormik({
    initialValues: {
      startDate: startDate || "",
      endDate: endDate || "",
      property_category: "",
      property_type_id: "",
      amenities: [],
      features: [],
      price_min: priceRange[0],
      price_max: priceRange[1],
      guest_number: "",
      bedrooms: "",
      bathrooms: "",
      location: "",
    },
    onSubmit: () => {},
  });

  const triggerSearch = debounce(async () => {
    const values = formik.values;

    const amenities = Array.isArray(values.amenities)
      ? values.amenities.join(",")
      : "";
    const features = Array.isArray(values.features)
      ? values.features.join(",")
      : "";

    const searchQuery = `q[property_type_id]=${values.property_type_id}
    &q[property_category_id]=${values.property_category}
    &q[amenity_ids]=${amenities}
    &q[min_price]=${values.price_min}
    &q[max_price]=${values.price_max}
    &q[total_bathrooms_gteq]=${values.bathrooms}
    &q[feature_ids]=${features}
    &q[total_bedrooms_gteq]=${values.bedrooms}
    &q[guest_number_gteq]=${values.guest_number}
    &q[longitude]=${userLocation?.longitude}
    &q[latitude]=${userLocation?.latitude}
    &q[startDate]=${startDate}
    &q[endDate]=${endDate}`;

    try {
      const action = await dispatch(searchProperties(searchQuery));

      if (action?.payload?.success) {
        navigate("/listing-stay-map", {
          state: { searchedData: action?.payload },
        });
      } else if (action?.payload?.status) {
        navigate("/listing-stay-map", {
          state: { searchedData: null },
        });
      }
    } catch (error) {
      console.error("Search API error:", error);
    }
  }, 300);

  useEffect(() => {
    if (
      formik.values.property_category ||
      formik.values.property_type_id ||
      formik.values.guest_number ||
      formik.values.bathrooms ||
      formik.values.bedrooms ||
      formik.values.amenities?.length > 0 ||
      formik.values.features?.length > 0
    ) {
      triggerSearch();
    }
    return triggerSearch.cancel;
  }, [
    formik.values.property_category,
    formik.values.property_type_id,
    formik.values.amenities,
    formik.values.features,
    formik.values.guest_number,
    formik.values.bathrooms,
    formik.values.bedrooms
  ]);

  const handlePriceChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      setPriceRange(value as [number, number]);
      formik.setFieldValue("price_min", value[0]);
      formik.setFieldValue("price_max", value[1]);
    } else {
      setPriceRange([value, value]);
      formik.setFieldValue("price_min", value);
      formik.setFieldValue("price_max", value);
    }
  };

  const fetchAddress = useCallback(
    async (latitude: number, longitude: number) => {
      const geocodeUrl = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${latitude}%2C${longitude}&lang=en-US&apiKey=${process.env.REACT_APP_LOCATION_KEY}`;

      try {
        const response = await fetch(geocodeUrl);
        const data = await response.json();
        const address = data?.items[0]?.address;
        if (address) {
          setAddress(address);
        } else {
          console.error("No address found");
        }
      } catch (error) {
        console.error("Error fetching geocode data:", error);
      }
    },
    [userLocation?.latitude, userLocation?.longitude]
  );

  const getUserLocation = () => {
    if (navigator.geolocation) {
      // setLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          await fetchAddress(latitude, longitude);
          // setLoading(false);
        },
        (error) => {
          console.error("Error get user location: ", error);
          //  setLoading(false);
        }
      );
    } else {
      //setLoading(false);
      console.log("Geolocation is not supported by this browser");
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-wrap gap-4 p-4">
        <div className="w-full sm:w-auto relative">
          <FormItem>
            <FormField
              label="Location"
              name="location"
              type="text"
              value={address?.label}
              onChange={() => {}}
              onBlur={formik.handleBlur}
              placeholder="Search location"
              error={
                formik.touched.location && formik.errors.location
                  ? formik.errors.location
                  : undefined
              }
            />
          </FormItem>
        </div>

        <div className="w-full sm:w-auto">
          <FormItem>
            <FormField
              label="Start Date"
              name="startDate"
              type="date"
              value={formik.values.startDate}
              onChange={(e) => {
                formik.setFieldValue("startDate", e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDate && formik.errors.startDate
                  ? String(formik.errors.startDate)
                  : undefined
              }
              isCrossRequired={true}
              isDOB={false}
            />
          </FormItem>
        </div>

        {/* End Date Input */}
        <div className="w-full sm:w-auto">
          <FormItem>
            <FormField
              label="End Date"
              name="endDate"
              type="date"
              value={formik.values.endDate}
              onChange={(e) => {
                formik.setFieldValue("endDate", e);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.endDate && formik.errors.endDate
                  ? String(formik.errors.endDate)
                  : undefined
              }
              isCrossRequired={true}
              isDOB={false}
            />
          </FormItem>
        </div>
        <div className="w-full sm:w-[100px] lg:w-[250px]">
          <FormItem>
            <FormField
              label="Choose a property Category"
              name="property_category"
              type="select"
              value={formik.values.property_category}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue("property_type_id", "");
              }}
              onBlur={formik.handleBlur}
              options={
                propertiesCategories?.map((item) => ({
                  value: String(item.id),
                  label: `${item.name} (${item.property_count || 0})`,
                })) || []
              }
              error={
                formik.touched.property_category &&
                formik.errors.property_category
                  ? formik.errors.property_category
                  : undefined
              }
            />
          </FormItem>
        </div>

        <div className="w-full sm:w-[100px] lg:w-[250px]">
          <FormItem>
            <FormField
              label="Choose a property type"
              name="property_type_id"
              type="select"
              value={formik.values.property_type_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={filteredPropertyTypes.map((type) => ({
                value: String(type.id),
                label: `${type.name} (${type.property_count || 0})`,
              }))}
              error={
                formik.touched.property_type_id &&
                formik.errors.property_type_id
                  ? formik.errors.property_type_id
                  : undefined
              }
            />
          </FormItem>
        </div>

        <div className="w-full sm:w-[100px] lg:w-[250px]">
          <FormItem>
            <FormField
              label="Guest Number"
              name="guest_number"
              type="select"
              value={formik.values.guest_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={[
                ...Array.from({ length: 15 }, (_, i) => ({
                  value: String(i + 1),
                  label: `${i + 1} Guest${i > 0 ? "s" : ""}`,
                })),
                //{ value: "16", label: "> 15 Guests" },
              ]}
              error={
                formik.touched.guest_number && formik.errors.guest_number
                  ? formik.errors.guest_number
                  : undefined
              }
            />
          </FormItem>
        </div>

        <div className="w-full sm:w-[100px] lg:w-[250px]">
          <FormItem>
            <FormField
              label="Choose amenities"
              name="amenities"
              type="select"
              value={formik.values.amenities}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedOptions = Array.from(
                  e.target.selectedOptions,
                  (option) => option.value
                );
                formik.setFieldValue("amenities", selectedOptions);
              }}
              onBlur={formik.handleBlur}
              options={
                amenitiesCategories?.map((anamity) => ({
                  value: String(anamity.id),
                  label: anamity.name,
                })) || []
              }
              error={
                formik.touched.amenities &&
                typeof formik.errors.amenities === "string"
                  ? formik.errors.amenities
                  : undefined
              }
            />
          </FormItem>
        </div>
        <div className="w-full sm:w-[100px] lg:w-[250px]">
          <FormItem>
            <FormField
              label="Choose property feature"
              name="features"
              type="select"
              value={formik.values.features}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedOptions = Array.from(
                  e.target.selectedOptions,
                  (option) => option.value
                );
                formik.setFieldValue("features", selectedOptions);
              }}
              onBlur={formik.handleBlur}
              options={
                featuresCategories?.map((feature: any) => ({
                  value: String(feature?.id),
                  label: feature?.name,
                })) || []
              }
              error={
                formik.touched.features &&
                typeof formik.errors.features === "string"
                  ? formik.errors.features
                  : undefined
              }
            />
          </FormItem>
        </div>

        {/* Amenities Selection */}

        <div className="w-full sm:w-[100px] lg:w-[250px]">
          <FormItem>
            <FormField
              label="Bedrooms"
              name="bedrooms"
              type="text"
              value={formik.values.bedrooms}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Bedrooms"
              error={
                formik.touched.bedrooms && formik.errors.bedrooms
                  ? formik.errors.bedrooms
                  : undefined
              }
            />
          </FormItem>
        </div>

        <div className="w-full sm:w-[100px] lg:w-[250px]">
          <FormItem>
            <FormField
              label="Bathrooms"
              name="bathrooms"
              type="text"
              value={formik.values.bathrooms}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Bathrooms"
              error={
                formik.touched.bathrooms && formik.errors.bathrooms
                  ? formik.errors.bathrooms
                  : undefined
              }
            />
          </FormItem>
        </div>

        <div className="w-80">
          <FormItem>
            <label className="text-m font-normal">Price Range</label>
            <div className="flex items-center space-x-4 w-full">
              <span className="text-orange-500">
                ${convertNumbThousand(priceRange[0])}
              </span>
              <Slider
                range
                min={0}
                max={5000}
                step={1}
                value={priceRange}
                onChange={handlePriceChange}
                className="w-full"
                styles={{
                  track: {
                    backgroundColor: "#FF7A00",
                    height: 6,
                  },
                  handle: {
                    borderColor: "#FF7A00",
                    backgroundColor: "#FF7A00",
                    borderRadius: 0,
                    height: 15,
                    width: 15,
                  },
                  rail: {
                    backgroundColor: "#d3d3d3",
                    height: 6,
                  },
                }}
              />
              <span className="text-orange-500">
                ${convertNumbThousand(priceRange[1])}
              </span>
            </div>
          </FormItem>
        </div>

        {/* <div className="w-full flex justify-end mt-4">
          <ButtonPrimary
            type="submit"
            //onClick={() => formik.handleSubmit()} // Trigger Formik submit
          >
            Search
          </ButtonPrimary>
        </div> */}

        {/* {renderTabsTypeOfPlace()} */}
        {/* {renderTabsPriceRage()} */}
        {/* {renderTabsRoomAndBeds()} */}
        {/* {renderTabMoreFilter()} */}

        {/* {renderTabMoreFilterMobile()} */}
      </div>
    </form>
  );
};

export default TabFilters;
