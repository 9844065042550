import "./styles/index.css";

import { FC, Fragment, useEffect, useRef, useState } from "react";
import Modal from "./components/Modal";
import type { ListingGalleryImage } from "./utils/types";
import { useLastViewedPhoto } from "./utils/useLastViewedPhoto";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
//@ts-ignore
import { Dialog, Transition } from "@headlessui/react";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import LikeSaveBtns from "components/LikeSaveBtns";
import NcImage from "shared/NcImage/NcImage";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import "react-loading-skeleton/dist/skeleton.css";

const PHOTOS: string[] = [
  "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

export const DEMO_IMAGE: ListingGalleryImage[] = [...PHOTOS]?.map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const getNewParam = ({
  paramName = "photoId",
  value,
}: {
  paramName?: string;
  value: string | number;
}) => {
  let params = new URLSearchParams(document.location.search);
  params.set(paramName, String(value));
  return params.toString();
};

interface Props {
  images?: ListingGalleryImage[];
  onClose?: () => void;
  isShowModal: boolean;
}

const ListingImageGallery: FC<Props> = ({
  images = DEMO_IMAGE,
  onClose,
  isShowModal,
}) => {
  let [searchParams] = useSearchParams();
  const photoId = searchParams?.get("photoId");
  const navigate = useNavigate();
  const [lastViewedPhoto, setLastViewedPhoto] = useLastViewedPhoto();
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lastViewedPhotoRef = useRef<HTMLDivElement>(null);
  const thisPathname = useLocation().pathname;
  const { slug } = useParams();
  const id = slug;
  const propertyId = globalId || slug;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (lastViewedPhoto && !photoId) {
      lastViewedPhotoRef.current?.scrollIntoView({ block: "center" });
      setLastViewedPhoto(null);
    }
  }, [photoId, lastViewedPhoto, setLastViewedPhoto]);
  const handleClose = () => {
    onClose && onClose();
    const returnUrl = `/listing-stay-detail/${slug}`;
    navigate(returnUrl, { replace: true });
  };

  useEffect(() => {
    if (isShowModal) {
      setLoading(true);
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [isShowModal]);
  const numColumns = images?.length <= 2 ? 2 : 3;
  const renderContent = () => {
    const placeholderCount = images?.length || 6;
    if (loading) {
      return (
        <div className={`grid grid-cols-1 sm:grid-cols-${numColumns} xl:grid-cols-${numColumns} gap-1`}
        style={{ gridAutoRows: "300px" }} >
        {Array.from({ length:  placeholderCount }).map((_, idx) => (
          <div
            key={idx}
            className="mb-4 h-[300px] bg-neutral-300 dark:bg-neutral-700 rounded-lg animate-pulse"
          />
        ))}
      </div>
      );
    }
    return (
<div
      className={`grid grid-cols-1 sm:grid-cols-${numColumns} xl:grid-cols-${numColumns} gap-1`}
      style={{ gridAutoRows: "300px" }}
    >
      {images?.map(({ id, url }) => (
        <div key={id} className="relative w-full overflow-hidden">
          <NcImage
            src={url}
            alt={`Listing gallery image ${id}`}
            className="w-full h-full rounded-lg absolute inset-0 object-cover sm:rounded-xl"
          />
        </div>
      ))}
    </div>

);
};


  return (
    <>
      {photoId && (
        <Modal
          images={images}
          onClose={() => {
            // @ts-ignore
            setLastViewedPhoto(photoId);
            let params = new URLSearchParams(document.location.search);
            params.delete("photoId");
            navigate(`${thisPathname}/?${params.toString()}`);
          }}
        />
      )}
      <Transition appear show={isShowModal} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-white dark:bg-black" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto ">
            <div className="sticky z-10 top-0 p-4 xl:px-10 flex items-center justify-between bg-white dark:bg-black">
              <button
                className="text-neutral-700 text-2xl font-bold focus:outline-none focus:ring-0 w-14 h-14 mt-4 rounded-full flex items-center justify-center hover:bg-neutral-300 dark:hover:bg-neutral-700 ml-auto dark:text-white"
                onClick={handleClose}
              >
                X
              </button>
            </div>

            <div className="flex min-h-full items-center justify-center sm:p-4 pt-0 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-5"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-5"
              >
                <Dialog.Panel className="w-full max-w-screen-lg mx-auto transform p-4 pt-0 text-left transition-all ">
                  {renderContent()}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ListingImageGallery;
