import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import { Property } from "redux/reducers/Properties/PropertiesSliceTypes";
import { getallPropertieslisting } from "../../../redux/reducers/Properties/PropertiesSlice";
import SectionGridFeaturePlacesV4 from "containers/PageHome/SectionGridFeaturesPlacesV4";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "containers/PageHome/HeaderFilter";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";

const MyListing: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [allData, setAllData] = useState<StayDataType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingPrev, setLoadingPrev] = useState(false);

  const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

  const { loading } = useSelector((state: RootState) => state.property);

  const getRandomIndex = () => Math.floor(Math.random() * 8);

  const mergedData = (dataToBeMerged: Property[]) => {
    const newData = dataToBeMerged?.map((apiItem) => {
      const randomIndex = getRandomIndex();
      const fallback = DEMO_DATA[randomIndex];
      return {
        ...fallback,
        id: apiItem?.id,
        title: apiItem?.title || fallback?.title,
        place_images: apiItem?.place_images,
        address: apiItem?.address,
        availability_start: apiItem.availability_start || fallback?.date,
        availability_end: apiItem.availability_end || fallback?.date,
        price: apiItem?.price_per_month,
        href: `/listing-stay-detail/${apiItem?.slug}`,
        property_state: apiItem?.property_state || null,
        active: apiItem?.active !== undefined ? apiItem.active : false,
        property_category: apiItem?.property_category.name,
        property_type: apiItem?.property_type?.name,
        city: apiItem?.city,
        country: apiItem?.country,
        slug: apiItem?.slug,
      };
    });
    setAllData(newData);
  };

  const heading = "My Property";

  const fetchData = async (pge: number) => {
    const data = {
      page: pge,
      query: query,
    };
    setIsLoading(true);
    try {
      const res = await dispatch(getallPropertieslisting(data)).unwrap();
      mergedData(res?.properties);
      setTotalPages(res?.meta?.total_pages || 1);
    } catch (error) {
      console.error("Error fetching properties:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadMore = async () => {
    if (page >= totalPages) return;

    setLoadingNext(true);
    const nextPage = page + 1;
    try {
      await fetchData(nextPage);
      setPage(nextPage);
    } catch (error) {
      console.error("Error while loading more:", error);
    } finally {
      setLoadingNext(false);
    }
  };

  const handleGoBack = async () => {
    if (page <= 1) return;

    setLoadingPrev(true);
    const previousPage = page - 1;
    try {
      await fetchData(previousPage);
      setPage(previousPage);
    } catch (error) {
      console.error("Error while going back:", error);
    } finally {
      setLoadingPrev(false);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      await fetchData(1);
      setPage(1);
    } catch (error) {
      console.error("Error while searching:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (query === "") {
      fetchData(1);
    }
  }, [query]);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <div className="relative py-16">
          <BackgroundSection />
          <div className="nc-SectionGridFeaturePlaces relative">
            <HeaderFilter
              tabActive={"New York"}
              heading={heading}
              tabs={["New York", "Los Angeles", "Chicago"]}
              onClickTab={(tab) => console.log("Tab clicked:", tab)}
            />
            <div className="nc-MyListing">
              <div className="mb-8">
                <div className="flex items-center">
                  <input
                    type="text"
                    value={query}
                    onChange={handleSearchChange}
                    placeholder="Search for a property..."
                    className="p-3 w-full md:w-1/3 border rounded-lg"
                  />
                  <button
                    onClick={handleSearch}
                    className={`ml-4 p-3 rounded-lg text-white ${
                      isLoading || query === ""
                        ? "bg-gray-400 cursor-not-allowed" 
                        : "bg-orange-500 hover:bg-orange-500"
                    }`}
                    disabled={isLoading || query === ""}
                  >
                    {isLoading ? "Searching..." : "Search"}
                  </button>
                </div>
              </div>
              <SectionGridFeaturePlacesV4
                stayListings={allData}
                setAllData={setAllData}
                query={query}
                setQuery={setQuery}
                fetchOldData={() => fetchData(page)}
                page={page}
                setPage={setPage}
                isLoading={isLoading}
              />
              {totalPages > 1 && (
                <div className="flex mt-16 justify-center items-center space-x-4">
                  <ButtonPrimary
                    loading={loadingPrev}
                    onClick={handleGoBack}
                    disabled={loadingPrev || page <= 1}
                  >
                    Go Back
                  </ButtonPrimary>
                  <ButtonPrimary
                    loading={loadingNext}
                    onClick={handleLoadMore}
                    disabled={loadingNext || page >= totalPages}
                  >
                    Show me more
                  </ButtonPrimary>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyListing;
