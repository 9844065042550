import React, { FC } from "react";

interface Props {
  dayOfMonth: number;
  date?: Date;
  booked_dates?: [string, string][]; // Mark booked_dates as optional
}

const DatePickerCustomDay: FC<Props> = ({ dayOfMonth, date, booked_dates }) => {
  // If booked_dates is not provided, return the default value of false
  const isBookedDate = (date: Date) => {
    if (!booked_dates) return false; // Return false if no booked_dates are provided
    return booked_dates.some(([start, end]: [string, string]) => {
      const startDate = new Date(start).getTime();
      const endDate = new Date(end).getTime();
      const currentDate = date.getTime();
      return currentDate >= startDate && currentDate <= endDate;
    });
  };

  // If the date is booked, apply the red background and disable it
  const booked = date ? isBookedDate(date) : false;

  return (
    <span
      className={`react-datepicker__day_span ${
        booked ? "bg-red-500 text-white cursor-not-allowed" : ""
      }`}
    >
      {dayOfMonth}
    </span>
  );
};

export default DatePickerCustomDay;
