import React, { useState, useEffect, useRef } from "react";
import { User } from "../../../redux/reducers/UserSlice/UserSliceType";
import moment from "moment";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { deleteMessage } from "../../../redux/reducers/UserSlice/UserSlice";
import { Message } from "redux/reducers/ChatReducer/ChatSliceTypes";

interface MessageItemType {
  message: any;
  currentUser: User | null;
  selectChat: any;
  getName: (name: string) => string;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}

const MessageItem = ({
  message,
  currentUser,
  selectChat,
  getName,
  setMessages
}: MessageItemType) => {
  const [menuVisible, setMenuVisible] = useState<{ [key: string]: boolean }>(
    {}
  );
  const menuRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const dispatch = useDispatch<AppDispatch>();

  const myId = currentUser?.id;
  const isHost = currentUser?.is_host;
  const isStudent = currentUser?.is_student;

  const toggleMenu = (messageId: string) => {
    setMenuVisible((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
  };

  const handleDelete = async (messageId: string) => {
    try {
      const numericMessageId = Number(messageId);

      const action = await dispatch(
        deleteMessage({ chatId: selectChat?.id, messageId: numericMessageId })
      );
      toggleMenu(messageId);

      if (action?.payload?.success) {
        setMessages((prevMessages) => {
          const updatedMessages = prevMessages?.map((group: any) => {
            const filteredMessages = group.messages.filter(
              (message: any) => message.id !== numericMessageId
            );
            return { ...group, messages: filteredMessages };
          });
          return updatedMessages.filter((group: any) => group.messages.length > 0);
        });
      }

    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const shouldShowMessage = (item: any) => {
    const isCurrentUser = myId === item.sender_id;
    return (
      item.event_for === "both" ||
      (isHost && item.event_for === "host") ||
      (isStudent && item.event_for === "student") ||
      isCurrentUser
    );
  };

  const getMessageAlignment = (item: any) => {
    const isCurrentUser = myId === item.sender_id;

    if (item.event_for === "both") {
      return isCurrentUser ? "justify-end" : "justify-start";
    }

    if (item.event_for === "host" || item.event_for === "student") {
      return "justify-center";
    }

    return isCurrentUser ? "justify-end" : "justify-start";
  };

  const isRoleSpecificMessage = (eventFor: string) => {
    return eventFor === "host" || eventFor === "student";
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      Object.keys(menuVisible).forEach((key) => {
        if (
          menuVisible[key] &&
          menuRefs.current[key] &&
          !menuRefs.current[key]?.contains(event.target as Node)
        ) {
          setMenuVisible((prev) => ({
            ...prev,
            [key]: false,
          }));
        }
      });
    };

    if (Object.values(menuVisible).some((visible) => visible)) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuVisible]);

  return (
    <div className="p-4 space-y-4">
      <h1 className="text-center text-black text-sm font-bold dark:text-white">
        {message.date}
      </h1>

      {message.messages?.map((item: any) => {
        if (!shouldShowMessage(item)) {
          return null;
        }

        const isCurrentUser = myId === item.sender_id;
        const isRoleMessage = isRoleSpecificMessage(item.event_for);
        const messageAlignment = getMessageAlignment(item);

        const messageTime = moment
          .utc(item?.created_at)
          .local()
          .format("hh:mm A");

        const isAttachements = item?.attachments?.length > 0;
        const imageUri = item?.attachments[0];

        return (
          <div key={item.id} className={`flex ${messageAlignment} relative`}>
            <div className="h-100 w-100 space-y-1">
              {!isRoleMessage && !isCurrentUser && (
                <img
                  src={
                    selectChat?.show?.image ||
                    "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
                  }
                  alt="Profile"
                  className="w-8 h-8 rounded-full mt-6 mr-1"
                />
              )}
            </div>
            <div className="flex items-start space-y-1 space-x-2 max-w-[65%] min-w-[5%]">
              <div className="flex flex-col space-y-1 relative">
                {!isRoleMessage && !isCurrentUser && (
                  <span className="text-xs self-start text-gray-500 dark:text-white flex items-center space-x-2">
                    <span>{getName(selectChat?.show?.name)}</span>
                    <span>{messageTime}</span>
                  </span>
                )}

                {!isRoleMessage && isCurrentUser && (
                  <div className="flex items-center justify-end space-x-2">
                    <span className="text-xs text-gray-500 dark:text-white self-end">
                      {messageTime}
                    </span>

                    <button
                      onClick={() => toggleMenu(item.id)}
                      className="text-gray-500 hover:text-gray-700 dark:text-white text-2xl ml-2"
                    >
                      &#x2026;
                    </button>
                    {menuVisible[item.id] && (
                      <div
                        ref={(ref) => (menuRefs.current[item.id] = ref)}
                        className="absolute bottom-20 left-22  bg-white dark:bg-gray-800 shadow-lg rounded-md p-2 z-50"
                      >
                        <button
                          onClick={() => handleDelete(item.id)}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                )}

                <div
                  className={`p-3 rounded-lg ${
                    isRoleMessage
                      ? "text-center text-black dark:text-white"
                      : isCurrentUser
                      ? "bg-gray-600 text-left text-white"
                      : "bg-gray-200 text-left text-black"
                  }`}
                >
                  {!isRoleMessage && isAttachements && (
                    <div>
                      <img
                        alt="attachment"
                        width={300}
                        height={200}
                        className="rounded-md object-cover"
                        src={imageUri || ""}
                      />
                    </div>
                  )}
                  <span
                    style={{ fontFamily: "Roboto, sans-serif" }}
                    className="text-sm"
                  >
                    {item.body}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MessageItem;
