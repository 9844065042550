import { Modal } from "react-rainbow-components";

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  description: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  description,
}) => {
  return (
    <Modal id="modal-1" isOpen={isOpen} onRequestClose={onClose}>
      <p className="p-5 text-sm text-center">{description}</p>
    </Modal>
  );
};

export default CustomModal;
