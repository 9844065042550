import { Popover, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
  LifebuoyIcon,
  CalendarIcon,
  InformationCircleIcon,
  PlusIcon,
  DocumentTextIcon,
  BookOpenIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "redux/store";
import Avatar from "shared/Avatar/Avatar";
import { logout } from "../../redux/reducers/AuthSlice/AuthSlice";

const solutions = [
  {
    name: "Account",
    href: "/account",
    icon: UserCircleIcon,
    role: 'common',
  },
  {
    name: "Messages",
    href: "/messages",
    icon: ChatBubbleBottomCenterTextIcon,
    role: 'common',
  },
  {
    name: "Manage Bookings",
    href: "/manage_bookings",
    icon: BookOpenIcon,
    role: 'host',
  },
  {
    name: "My Bookings",
    href: "/my_bookings",
    icon: BookOpenIcon,
    role: 'common',
  },
  {
    name: "Wishlists",
    href: "/account-savelists",
    icon: HeartIcon,
    role: 'student',
  },
  {
    name: "Booking-Queries",
    href: "/booking_queries",
    icon: UserCircleIcon,
    role: 'common',
  },
  {
    name: "My Reservation",
    href: "/manage_bookings",
    icon: BookOpenIcon,
    role: 'student',
  },
  {
    name: "Invoices",
    href: "##",
    icon: DocumentTextIcon,
    role: 'common',
  },
  {
    name: "List New Property",
    href: "add-listing-1",
    icon: PlusIcon,
    role: 'host',
  },
  {
    name: "My Properties",
    href: "/my_propeties",
    icon: HomeIcon,
    role: 'host',
  },
  {
    name: "Calendar",
    href: "##",
    icon: CalendarIcon,
    role: 'host',
  },
  // {
  //   name: "Help",
  //   href: "##",
  //   icon: InformationCircleIcon,
  //   role: 'host',
  // },
];

export default function AvatarDropdown() {
 const [isOpen, setIsOpen] = useState(false);
 const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state: RootState) => state.user);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
    setIsOpen(false); 
  };

  const solutionsFoot = [
    {
      name: "Help",
      href: "##",
      icon: LifebuoyIcon,
      onClick: function () { },
    },
    {
      name: "Logout",
      href: "##",
      icon: ArrowRightOnRectangleIcon,
      onClick: handleLogout,
    },
  ];

solutionsFoot[1].onClick = handleLogout;

const toggleDropdown = () => setIsOpen(!isOpen); // Toggle visibility

useEffect(() => {
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

  return (
    <div className="AvatarDropdown" ref={dropdownRef}>
      <Popover className="relative">

          <>
            <Popover.Button
            onClick={toggleDropdown}
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" imgUrl={currentUser?.image || ''} userName={currentUser?.first_name} />
            </Popover.Button>
            <Transition
              show={isOpen}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {solutions.map((item, index) => {
                      const isStudent = currentUser?.is_student && item.role === 'student';
                      const isHost = currentUser?.is_host && item.role === 'host';
                      const isCommon = item.role === 'common';

                      if (isStudent || isHost || isCommon) {
                        return (
                          <Link
                            key={index}
                            to={item.href}
                            onClick={() => setIsOpen(false)} 
                            className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                              <item.icon aria-hidden="true" className="w-6 h-6" />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium">{item.name}</p>
                            </div>
                          </Link>
                        );
                      }

                      return null;
                    })}
                  </div>
                  <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {solutionsFoot.map((item, index) => (
                      <button
                        key={index}
                        onClick={() => { item.onClick?.();
                         setIsOpen(false);
                         }}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium">{item.name}</p>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        
      </Popover>
    </div>
  );
}
