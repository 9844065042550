import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import CommonLayout from "./CommonLayout";
import { AppDispatch, RootState } from "redux/store";
import { Alert } from "../../shared/Alert/Alert";
import Details from "./Details";
import SocialMediaLink from "./SocialMediaLink";
import PaymentInfo from "./PaymentInfo";
import Educations from "./Educations";
import Notifications from "./Notifications";
import DeleteAccount from "./DeleteAccount";
import AccountInfo from "./AccountInfo";
import {
  getCurrentUser,
  updateUserEmailAsVerified,
} from "../../redux/reducers/UserSlice/UserSlice";
import { useEffect, useState } from "react";
import successHandler from "../../utils/helpers/SuccessHandler";
import { toast } from "react-toastify";

const AccountPage = ({ className = "" }) => {
  const { currentUser, loading } = useSelector(
    (state: RootState) => state.user
  );
  const [localCount, setLocalCount] = useState(0);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (
      currentUser &&
      currentUser?.email_verified &&
      currentUser?.sign_in_count === 1 &&
      localCount === 0
    ) {
      dispatch(updateUserEmailAsVerified())
        .unwrap()
        .then((res) => {
          if (res) {
            toast.dismiss();
            successHandler("Email verified successfully!");
            dispatch(getCurrentUser());
            setLocalCount(1);
          }
        })
        .catch((error) => {
          console.error("Error verifying email: ", error);
        });
    }
  }, [currentUser, localCount, dispatch]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Booking React Template</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* Alerts Section */}
          {currentUser &&
            (!currentUser.email_verified ||
              (currentUser.is_host &&
                !currentUser.host_verified &&
                currentUser.email_verified)) && (
              <div className="border p-4 rounded-md mb-6">
                {!currentUser.email_verified && (
                  <Alert type="info">
                    Info! Please verify your email via the link sent to your
                    inbox to access other pages.
                  </Alert>
                )}
                {currentUser.is_host &&
                  !currentUser.host_verified &&
                  currentUser.email_verified && (
                    <Alert type="info">
                      Info! Once the admin approves your profile, you can list
                      your properties.
                    </Alert>
                  )}
              </div>
            )}
          {/* Account Information Section */}
          <div className="border bg:border-grey p-8 rounded-lg shadow-sm bg-white dark:bg-neutral-900 mb-6">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-2">
              Account Information
              <span className="text-lg font-medium text-gray-500 dark:text-gray-400">
                ({currentUser?.is_student ? "Student" : "Host"})
              </span>
            </h2>

            <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
              View and update your account information here.
            </p>
            <div className="w-full border-b border-gray-300 dark:border-gray-700 mb-6"></div>
            {currentUser?.is_host && <AccountInfo />}
          </div>

          {/* Student Details Section */}
          {currentUser?.is_student && (
            <div className="border p-6 rounded-md mb-6 space-y-4">
              <h3 className="text-2xl font-semibold">Student Details</h3>
              <Details />
              <PaymentInfo />
              <Educations />
            </div>
          )}

          {/* Social Media Section */}
          <div className="border p-6 rounded-md mb-6">
            <h3 className="text-2xl font-semibold">Social Media</h3>
            <SocialMediaLink />
          </div>

          {/* Notifications Section */}
          <div className="border p-6 rounded-md mb-6">
            <h3 className="text-2xl font-semibold">Whatsapp & SMS</h3>
            <Notifications />
          </div>

          {/* Account Deletion Section */}
          <div className="border p-6 rounded-md mb-6 bg-red-50 dark:bg-neutral-800">
            <h3 className="text-2xl font-semibold text-red-600 dark:text-orange-300">
              Danger Zone
            </h3>
            <DeleteAccount />
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
