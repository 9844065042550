import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Chat } from "../../redux/reducers/ChatReducer/ChatSliceTypes";
import { User } from "../../redux/reducers/UserSlice/UserSliceType";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { resetCount } from "../../redux/reducers/ChatReducer/ChatSlice";
import "react-loading-skeleton/dist/skeleton.css";
import { defaultProfileImage, defaultPropertyImage } from "../../utils/defaultImages";

interface ChatListProps {
  loading: boolean;
  currentUser: User | null;
  chats: any;
  selectChat: any;
  setSelectedChat: React.Dispatch<React.SetStateAction<Chat | null>>;
  getAllMessages: (chatId: number, page: number) => void;
  loadMoreChats: () => void;
  setChats: React.Dispatch<React.SetStateAction<any[]>>;
}

const ChatList: React.FC<ChatListProps> = ({
  chats,
  loading,
  selectChat,
  setSelectedChat,
  getAllMessages,
  currentUser,
  loadMoreChats,
  setChats,
}) => {
  const chatListRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleScroll = () => {
    if (chatListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatListRef.current;
      const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
      if (scrollPercentage > 0.95 && !loading && !isScrolledToBottom) {
        setIsScrolledToBottom(true);
        loadMoreChats();
      } else if (scrollPercentage < 0.95) {
        setIsScrolledToBottom(false);
      }
    }
  };

  useEffect(() => {
    const chatListElement = chatListRef.current;
    if (chatListElement) {
      chatListElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatListElement) {
        chatListElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, isScrolledToBottom]);


  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  useEffect(() => {
    if (!loading) {
      setIsScrolledToBottom(false);
    }
  }, [loading]);

  const finalChats = chats?.map((chat: any) =>
    chat.sender_id === currentUser?.id
      ? { ...chat, show: chat.receiver }
      : { ...chat, show: chat.sender }
  );
  const resetParticularCount = (id: number) => {
    // dispatch(resetCount(id))
  };

  const getName = (name: string) => {
    const modifiedName = name[0]?.toUpperCase() + name?.slice(1);
    return modifiedName;
  };

  const getLastMessage = (lastMessage: string) => {
    return lastMessage ? lastMessage : null;
  };

  const getMessageTime = (time: string) => {
    return time ? time : null;
  };

  const chatHandler = (chat: any) => {
    setSelectedChat(chat);
    getAllMessages(chat.id, 1);
    setChats((prevChats) =>
      prevChats.map((c) =>
        c.id === chat.id ? { ...c, unread_count: 0 } : c
      )
    );
    dispatch(resetCount(chat.id));

  };

  // const defaultProfileImage = "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png";
  return (
    <div
    ref={chatListRef} className="mt-6 max-h-[calc(90vh-80px)] overflow-y-auto overflow-x-hidden">
      <div className="chat-content" style={{ height: "calc(90vh - 100px)" }}>
        {/* {loading ? <span>loading....</span> : (
      finalChats?.map((chat: any, index: any) => (
        <button
          key={index}
          className={`w-full flex items-center py-4 px-2 ${selectChat?.id === chat?.id ? 'bg-gray-200 dark:bg-gray-600' : 'bg-neutral'}  hover:bg-neutral-200 dark:hover:bg-neutral-700 cursor-pointer rounded-lg dark:border-neutral-700 focus:outline-none`}
          onClick={() => {
            chatHandler(chat)
            resetParticularCount(chat?.id)
          }} */}
        {loading ? (
          <div className="w-full animate-pulse">
            <div className="w-full h-[100px] bg-neutral-300 dark:bg-neutral-700 rounded-lg mb-3"></div>
          </div>
        ) : finalChats && finalChats.length > 0 ? (
          finalChats.map((chat: any, index: any) => (
            <button
              key={index}
              className={`w-full flex items-center py-4 px-2 ${
                selectChat?.id === chat?.id
                  ? "bg-gray-200 dark:bg-gray-600"
                  : "bg-neutral"
              } hover:bg-neutral-200 dark:hover:bg-neutral-700 cursor-pointer rounded-lg dark:border-neutral-700 focus:outline-none`}
              onClick={() => {
                chatHandler(chat);
                // resetParticularCount(chat?.id);
              }}
            >
              <div className="relative flex-shrink-0 mr-4">
                {/* Property Image */}
                <img
                  src={chat.property?.place_images?.[0] || defaultPropertyImage}
                  alt={chat.property?.title || "Property Image"}
                  className="w-16 h-16 rounded-lg object-cover"
                  style={{ maxWidth: "54px", maxHeight: "54px" }}
                  onError={(e) => {
                    console.error(
                      "Property image failed to load:",
                      e.currentTarget.src
                    );
                    e.currentTarget.src = defaultPropertyImage;
                  }}
                />
                {/* Profile Image (half overlapping) */}
                <img
                  src={chat.show.image || defaultProfileImage}
                  alt={chat.show.name || "User"}
                  className="w-9 h-9 rounded-full absolute bottom-0 right-0 border-2 border-white"
                  style={{
                    transform: "translate(25%, 25%)",
                    zIndex: 1,
                  }}
                  onError={(e) => {
                    console.error(
                      "Profile image failed to load:",
                      e.currentTarget.src
                    );
                    e.currentTarget.src = defaultProfileImage;
                  }}
                />
                <img
                  src={chat.sender?.image || defaultProfileImage}
                  alt={chat.sender?.name || "Sender"}
                  className="w-8 h-8 rounded-full absolute bottom-[-10px] right-0 border-2 border-white"
                  style={{
                    transform: "translate(-25%, 25%)",
                    zIndex: 1,
                  }}
                  onError={(e) => {
                    console.error(
                      "Sender image failed to load:",
                      e.currentTarget.src
                    );
                    e.currentTarget.src = defaultProfileImage;
                  }}
                />
              </div>
              <div className="ml-4 flex-1 min-w-0" style={{ width: "100%" }}>
                <div className="flex items-center justify-between">
                  <h3
                    style={{ fontFamily: "Roboto, sans-serif" }}
                    className="text-base text-neutral-500 dark:text-neutral-100 truncate"
                  >
                    {getName(chat.show.name)}
                  </h3>
                  <p className="text-xs text-neutral-500 dark:text-neutral-400 ml-2 whitespace-nowrap">
                    {getMessageTime(chat.last_message_time)}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="w-3/4 text-left text-sm text-neutral-500 dark:text-neutral-400 truncate">
                    {getLastMessage(chat.last_message)}
                  </p>
                  {chat.unread_count > 0 && (
                    <span className="text-xs text-white rounded-full h-5 w-5 truncate bg-red-500 flex items-center justify-center">
                      {chat.unread_count}
                    </span>
                  )}
                </div>
              </div>
            </button>
          ))
        ) : (
          <div className="text-center text-neutral-600 dark:text-neutral-400 mt-4">
            No chats found
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatList;
