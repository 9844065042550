import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import "./ReactDate.scss"; // Enhanced styles

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface ReactDateProps {
  availabilityStart: any;
  availabilityEnd: any;
  unlimitedAvailability: boolean;
  excludedDates: { start: Date; end: Date }[];
}

const ReactDate: React.FC<ReactDateProps> = ({
  availabilityStart,
  availabilityEnd,
  unlimitedAvailability,
  excludedDates,
}) => {
  const [value, setValue] = useState<Value>(new Date());

  useEffect(() => {
    if (availabilityStart && availabilityEnd) {
      setValue([new Date(availabilityStart), new Date(availabilityEnd)]);
    }
  }, [availabilityStart, availabilityEnd]);

  useEffect(() => {
    // Adjust value when unlimitedAvailability is true and availabilityStart is selected
    if (unlimitedAvailability && availabilityStart) {
      const startDate = new Date(availabilityStart);
      const oneYearLater = dayjs(startDate).add(1, "year").toDate();
      setValue([startDate, oneYearLater]); // Set range from start date to one year later
    }
  }, [unlimitedAvailability, availabilityStart]);

  
  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (view === "month") {
      const today = dayjs();
  
      // Today's date
      if (dayjs(date).isSame(today, "day")) {
        return "black-date";
      }
  
      // Selected date range
      if (Array.isArray(value) && value[0] && value[1]) {
        const [start, end] = value;
        if (
          dayjs(date).isAfter(dayjs(start).subtract(1, "day")) &&
          dayjs(date).isBefore(dayjs(end).add(0, "day"))
        ) {
          return "green-date";
        }
      }
  
      // Unlimited availability within one year of availabilityStart
      if (unlimitedAvailability && availabilityStart) {
        const startDate = dayjs(availabilityStart);
        const oneYearLater = startDate.add(1, "year");
        if (
          dayjs(date).isAfter(startDate.subtract(1, "day")) &&
          dayjs(date).isBefore(oneYearLater.add(1, "day"))
        ) {
          return "green-date";
        }
      }
  
      // Availability start date
      if (dayjs(date).isSame(dayjs(availabilityStart), "day")) {
        return "red-date";
      }
    }
    return null;
  };
  

  const tileDisabled = ({ date }: { date: Date }) => {
    // Disable all past dates
    if (dayjs(date).isBefore(dayjs().startOf("day"), "day")) {
      return true;
    }

    // Disable excluded dates
    for (const { start, end } of excludedDates) {
      if (
        dayjs(date).isAfter(dayjs(start).subtract(1, "day")) &&
        dayjs(date).isBefore(dayjs(end).add(1, "day"))
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <div className="react-calendar-container">
      <Calendar
        value={value}
        onChange={setValue}
        tileClassName={tileClassName}
        tileDisabled={tileDisabled}
        minDate={dayjs().startOf("day").toDate()}
      />
    </div>
  );
};

export default ReactDate;
