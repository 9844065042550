import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { BookingRequestDetails } from "../../redux/reducers/Properties/PropertiesSliceTypes";
import { AppDispatch } from "redux/store";
import {
  getBookingsDetails,
  sendBookingInvoice,
} from "../../redux/reducers/Properties/PropertiesSlice";
import { currencySymbol } from "shared/CurrencySymbol";
import moment from "moment";

interface InvoiceProps {
  bookingRequestDetails: BookingRequestDetails | null;
  closeInvoice: () => void;
  callback?: () => void;
}

const Invoice: FC<InvoiceProps> = ({ bookingRequestDetails, closeInvoice, callback }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [expenses, setExpenses] = useState<{ name: string; value: number }[]>(
    []
  );
  const [discounts, setDiscounts] = useState<number[]>([]);
  const [expenseName, setExpenseName] = useState("");
  const [expenseValue, setExpenseValue] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const addExpense = () => {
    if (expenseName && expenseValue) {
      setExpenses([
        ...expenses,
        { name: expenseName, value: parseFloat(expenseValue) },
      ]);
      setExpenseName("");
      setExpenseValue("");
    }
  };

  const addDiscount = () => {
    if (discountValue) {
      setDiscounts([...discounts, parseFloat(discountValue)]);
      setDiscountValue("");
    }
  };

  const totalExpenses = expenses.reduce((sum, exp) => sum + exp.value, 0);
  const totalDiscounts = discounts.reduce((sum, disc) => sum + disc, 0);
  const total =
    bookingRequestDetails?.total &&
    Number(bookingRequestDetails?.total) + (totalExpenses - totalDiscounts);

  const removeDiscount = (index: any) => {
    const updatedDiscounts = [...discounts];
    updatedDiscounts.splice(index, 1);
    setDiscounts(updatedDiscounts);
  };

  const removeExpense = (index: any) => {
    const updatedExpenses = [...expenses];
    updatedExpenses.splice(index, 1);
    setExpenses(updatedExpenses);
  };

  const sendInvoiceHandler = async () => {
    try {
      setIsLoading(true);

      const payloadData = {
        bookingId: bookingRequestDetails?.id,
        booking: {
          status: "invoice_sent",
          invoice_attributes: {
            status: "sent",
            billing_type: "one time",
            invoice_type: "Reservation fee",
            expenses_attributes: expenses?.map((x) => ({
              category: x.name,
              amount: x.value,
            })),
            discounts_attributes: discounts?.map((y) => ({
              description: "Seasonal discount",
              amount: y,
            })),
          },
        },
      };

      const action = await dispatch(sendBookingInvoice(payloadData));
      if (
        sendBookingInvoice.fulfilled.match(action) &&
        action.payload?.success
      ) {
        if (callback) {
          callback();
        }
        await dispatch(getBookingsDetails(bookingRequestDetails?.id)); // Call the get API
      } else {
        console.error(
          "Failed to send booking invoice",
          action?.payload?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error in sendInvoiceHandler:", error);
    } finally {
      setIsLoading(false);
      closeInvoice();
    }
  };

  return (
    bookingRequestDetails && (
      <div className="flex p-6 bg-gray-100 dark:bg-gray-900 justify-center mt-5 text-black dark:text-white">
        {/* Invoice Section */}
        <div className="w-2/3 p-6 border rounded shadow bg-white dark:bg-gray-800">
          <h2 className="text-xl font-medium mb-4">Create Invoice</h2>
          <div className="mb-6">
            <div className="flex flex-col space-y-2">
              <div className="flex justify-between">
                <span className="text-md text-gray-500 dark:text-gray-400">
                  Period :
                </span>
                <span className="text-md text-gray-500 dark:text-gray-400">
                  {moment(bookingRequestDetails?.check_in_date).format(
                    "MMM DD, YYYY"
                  )}{" "}
                  to{" "}
                  {moment(bookingRequestDetails?.check_out_date).format(
                    "MMM DD, YYYY"
                  )}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-md text-gray-500 dark:text-gray-400">
                  No. of Months :
                </span>
                <span className="text-md text-gray-500 dark:text-gray-400">
                  1
                </span>
              </div>

              <div className="flex justify-between">
                <span className="text-md text-gray-500 dark:text-gray-400">
                  No. of guests :
                </span>
                <span className="text-md text-gray-500 dark:text-gray-400">
                  {bookingRequestDetails?.number_of_guests}
                </span>
              </div>
            </div>
          </div>
          <div>
            {/* Invoice Table */}
            <table className="w-full mb-4">
              <thead>
                <tr className="border-b">
                  <th className="text-left font-medium">Detail</th>
                  <th className="text-left font-medium text-right">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b text-gray-500 dark:text-gray-400 text-sm">
                  <td className="pt-4 pb-4">First Month's Rent</td>
                  <td className="pt-4 pb-4 text-right">
                    {`${currencySymbol()} ${
                      Number(bookingRequestDetails?.item_total)?.toFixed(2) || 0
                    }`}
                  </td>
                </tr>
                {Number(bookingRequestDetails?.service_tax) > 0 && (
                  <tr className="border-b text-gray-500 dark:text-gray-400 text-sm">
                    <td className="pt-4 pb-4"> Time Service Fee :</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${Number(
                        bookingRequestDetails?.service_tax
                      )?.toFixed(2)}`}
                    </td>
                  </tr>
                )}

                {Number(bookingRequestDetails?.calculate_cleaning_fee) > 0 && (
                  <tr className="border-b text-gray-500 dark:text-gray-400 text-sm">
                    <td className="pt-4 pb-4">Cleaning Fee :</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${Number(
                        bookingRequestDetails?.calculate_cleaning_fee
                      )?.toFixed(2)}`}
                    </td>
                  </tr>
                )}

                {Number(bookingRequestDetails?.calculate_city_fee) > 0 && (
                  <tr className="border-b text-gray-500 dark:text-gray-400 text-sm">
                    <td className="pt-4 pb-4">City Fee :</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${Number(
                        bookingRequestDetails?.calculate_city_fee
                      )?.toFixed(2)}`}
                    </td>
                  </tr>
                )}

                {Number(bookingRequestDetails?.extra_guest_amount) > 0 && (
                  <tr className="border-b text-gray-500 dark:text-gray-400 text-sm">
                    <td className="pt-4 pb-4"> Extra guest amount : :</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${Number(
                        bookingRequestDetails?.extra_guest_amount
                      )?.toFixed(2)}`}
                    </td>
                  </tr>
                )}

                {Number(bookingRequestDetails?.invoice_total) > 0 && (
                  <tr className="border-b text-gray-500 dark:text-gray-400 text-sm p-6">
                    <td className="pt-4 pb-4"> Invoice total :</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${Number(
                        bookingRequestDetails?.invoice_total
                      )?.toFixed(2)}`}
                    </td>
                  </tr>
                )}

                {Number(bookingRequestDetails?.tax_total) > 0 && (
                  <tr className="border-b text-gray-500 dark:text-gray-400 text-sm p-6">
                    <td className="pt-4 pb-4">Tax total :</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${Number(
                        bookingRequestDetails?.tax_total
                      )?.toFixed(2)}`}
                    </td>
                  </tr>
                )}

                {expenses.map((expense, index) => (
                  <tr
                    key={index}
                    className="border-b text-gray-500 dark:text-gray-400 text-sm"
                  >
                    <td className="pt-4 pb-4">{expense.name}</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${
                        Number(expense.value)?.toFixed(2) || 0
                      }`}
                    </td>
                    <td>
                      <button
                        onClick={() => removeExpense(index)}
                        className="text-gray-400 dark:text-gray-500 text-xs"
                      >
                        ✖
                      </button>
                    </td>
                  </tr>
                ))}
                {discounts.map((discount, index) => (
                  <tr
                    key={index}
                    className="border-b text-gray-500 dark:text-gray-400 text-sm"
                  >
                    <td className="pt-4 pb-4">Discount</td>
                    <td className="pt-4 pb-4 text-right">
                      {`${currencySymbol()} ${
                        Number(discount)?.toFixed(2) || 0
                      }`}
                    </td>
                    <td>
                      <button
                        onClick={() => removeDiscount(index)}
                        className="text-gray-400 dark:text-gray-500 text-xs"
                      >
                        ✖
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Total */}
            <div className="flex justify-between font-medium">
              <span className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                Total
              </span>
              <span className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                {`${currencySymbol()} ${Number(total)?.toFixed(2)}`}
              </span>
            </div>
          </div>
          {/* Send Invoice Button */}
          <button
            onClick={sendInvoiceHandler}
            className={`mt-6 px-4 py-2 rounded ${
              isLoading ? "bg-gray-400" : "bg-orange-500"
            } text-white`}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div className="animate-spin border-t-2 border-white border-solid rounded-full w-6 h-6 mr-2"></div>
                Sending...
              </div>
            ) : (
              "Send Invoice"
            )}
          </button>
        </div>

        {/* Add Expense & Discount Section */}
        <div className="w-1/3 ml-6">
          <div className="mb-4">
            <h3 className="text-m font-medium mb-2">Add Extra Expense</h3>
            <input
              type="text"
              value={expenseName}
              onChange={(e) => setExpenseName(e.target.value)}
              placeholder="Type expense name"
              className="border p-2 w-full mb-2 rounded bg-gray-50 dark:bg-gray-800 text-black dark:text-white"
            />
            <div className="flex space-x-2 mb-2">
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center ml-2 text-gray-500">
                  {currencySymbol()}
                </span>
                <input
                  type="text"
                  value={expenseValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d*$/.test(value)) {
                      setExpenseValue(e.target.value);
                    }
                  }}
                  placeholder="Add amount"
                  className="border p-2 w-full pl-5 rounded bg-gray-50 dark:bg-gray-800 text-black dark:text-white"
                  min="0"
                />
              </div>
              <button
                onClick={addExpense}
                className="px-4 py-2 bg-orange-500 text-white h-full text-sm"
              >
                Add
              </button>
            </div>
          </div>
          <div>
            <h3 className="text-m font-medium mb-2">Add Discount</h3>
            <div className="flex space-x-2 mb-2">
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center ml-2 text-gray-500">
                  {currencySymbol()}
                </span>
                <input
                  type="text"
                  value={discountValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d*$/.test(value)) {
                      setDiscountValue(e.target.value);
                    }
                  }}
                  placeholder="Add amount"
                  className="border p-2 w-full  pl-5 rounded bg-gray-50 dark:bg-gray-800 text-black dark:text-white"
                  min="0"
                />
              </div>
              <button
                onClick={addDiscount}
                className="px-4 py-2 bg-orange-500 text-white h-full text-sm"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Invoice;
