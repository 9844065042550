import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  getallupdateProperties,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayoutV2 from "./CommonLayoutV2";
import "../../../src/shared/DateField/CustomDatePicker.scss";
import CheckboxV2 from "shared/Checkbox/CheckboxV2";
import FormItem from "./FormItem";
import ReactDate from "shared/DateField/ReactDate";
import moment from "moment";
import CustomDatePickerV2 from "shared/DateField/CustomDatePickerV2";
import { format } from "date-fns";
import * as Yup from "yup";

export interface ListingCalenderProps {}

const ListingCalender: FC<ListingCalenderProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { globalId } = useSelector((state: RootState) => state.property);
  const { slug } = useParams();
  const id = slug;
  const [excludedDates, setExcludedDates] = useState<
    { start: Date; end: Date }[]
  >([]);
  const RouteID = id;
  const [minimumMonth, setMinimumMonth] = useState<any>(2);
  const [initialValues, setInitialValues] = useState({
    availability_start: null,
    availability_end: null,
    unlimited_availability: false,
    minimum_months_of_booking: 0,
  });
  interface FormValues {
    minimum_months_of_booking: number;
    availability_start: string | null;
    availability_end: string | null;
    unlimited_availability: boolean;
  }

  const validationSchema = Yup.object().shape({
    availability_start: Yup.string().required(
      "Availability start date is required"
    ),
    availability_end: Yup.string().when(
      "unlimited_availability",
      ([unlimited_availability], schema) => {
        return unlimited_availability
          ? schema.nullable()
          : schema.required("Availability end date is required");
      }
    ),
    unlimited_availability: Yup.boolean(),
  });

  const isEditing = (currentValues: FormValues) => {
    return Object.entries(currentValues).some(([key, value]) => {
      return value !== initialValues[key as keyof FormValues];
    });
  };
  const formik = useFormik<FormValues>({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (!isEditing(values)) {
        navigate(`/add-listing-8/${slug}`);
        return;
      }
      const payload: any = {
        id: id,
        availability_start: values.availability_start,
        availability_end: values.unlimited_availability
          ? null
          : values.availability_end,
        unlimited_availability: values.unlimited_availability,
      };

      setSubmitting(true);
      dispatch(updateProperties({ property: payload }))
        .unwrap()
        .then((response: any) => {
          if (response) {
            successHandler(response?.message || "Updated successfully");
            resetForm();
            navigate(`/add-listing-8/${slug}`);
          }
        })
        .catch((error: any) => {
          console.error("Error updating property: ", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    const propertyId = id;
    if (propertyId) {
      dispatch(getallupdateProperties({ propertyId }))
        .unwrap()
        .then((response: any) => {
          if (response.success && response.property) {
            const {
              availability_start,
              availability_end,
              minimum_months_of_booking,
              unlimited_availability,
            } = response.property;

            setMinimumMonth(minimum_months_of_booking);
            formik.setFieldValue("availability_start", availability_start);
            formik.setFieldValue("availability_end", availability_end);
            formik.setFieldValue(
              "unlimited_availability",
              unlimited_availability
            );
            const newValues = {
              availability_start,
              availability_end,
              unlimited_availability,
              minimum_months_of_booking: minimum_months_of_booking || 0,
            };
            setInitialValues(newValues);
            formik.setValues(newValues);

            if (minimum_months_of_booking) {
              setMinimumMonth(minimum_months_of_booking);
            } else {
              setMinimumMonth(1);
            }
          }
        })
        .catch((error: any) => {
          console.error("Error fetching property: ", error);
          setMinimumMonth(1);
        });
    }
  }, [dispatch, id, minimumMonth]);

  const handleStartDateChange = (date: any) => {
    const formattedStartDate = date.format("YYYY-MM-DD");
    formik.setFieldValue("availability_start", formattedStartDate);
    const dynamicEndDate = moment(date)
      .add(minimumMonth, "months")
      .endOf("day");

    formik.setFieldValue(
      "availability_end",
      dynamicEndDate.format("YYYY-MM-DD")
    );
  };

  return (
    <CommonLayoutV2 currentHref="/add-listing-7" PropertyID={RouteID}>
      <div>
        <h2 className="text-2xl font-semibold">Set your availability</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Shorter trips can mean more reservations, but you'll turn over your
          space more often.
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

      {/* FORM */}
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-7">
          <div className="flex items-center justify-between p-2">
            <div className="text-lg font-medium">Availability</div>
            <FormItem label="">
              <CheckboxV2
                name="unlimited_availability"
                label="Unlimited Availability"
                onChange={(checked) => {
                  formik.setFieldValue("unlimited_availability", checked);
                  // Clear availability_end if unlimited_availability is checked
                  if (checked) {
                    formik.setFieldValue("availability_end", null);
                  }
                }}
                checked={formik.values.unlimited_availability}
              />
            </FormItem>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
            <FormItem label="Availability Start Date" className="mt-4">
              {/* <CustomDatePickerV2
                value={
                  formik.values.availability_start
                    ? dayjs(formik.values.availability_start)
                    : null
                }
                onChange={(date: any) => {
                  const formattedDate = date ? date.format("YYYY-MM-DD") : null;
                  formik.setFieldValue("availability_start", formattedDate);
                }}
                disabled={false}
                placeholder="Select Start Date"
                isCrossRequired={true}
                disabledDate={(current) => {
                  const today = dayjs().startOf("day");
                  const availabilityStart = dayjs(
                    formik.values.availability_start
                  ).startOf("day");

                  const availabilityEnd = dayjs(
                    formik.values.availability_end
                  ).startOf("day");

                  return (
                    (current && current.isBefore(today)) ||
                    (current && current.isBefore(availabilityStart)) ||
                    (current && current.isAfter(availabilityEnd)) ||
                    false
                  );
                }}
                className="my-custom-class"
              /> */}

              {/* <CustomDatePickerV2
                date={formik.values.availability_start}
                onDateChange={(date) => {
                  const formattedDate = date
                    ? format(date, "yyyy-MM-dd")
                    : null;
                  formik.setFieldValue("check_in_date", formattedDate);
                }}
                placeholder="Select Start Date"
                disabledDates={[]}
                minDate={new Date()}
                maxDate={''}
                isCrossRequired={true}
              /> */}

              <CustomDatePickerV2
                name="availability_start"
                value={formik.values.availability_start || ""}
                onChange={(value) => {
                  const formattedDate = value
                    ? format(value, "yyyy-MM-dd")
                    : null;
                  formik.setFieldValue("availability_start", formattedDate);
                }}
                placeholder="Select Start Date"
                minDate={new Date()}
                disabledDays={[]}
                isCrossRequired={true}
              />
              {formik.errors.availability_start &&
                formik.touched.availability_start && (
                  <div className="error text-red-500">
                    {formik.errors.availability_start}
                  </div>
                )}
            </FormItem>

            {!formik.values.unlimited_availability && (
              <FormItem label="Availability End Date" className="mt-4">
                {/* <CustomDatePickerV2
                  value={
                    formik.values.availability_end
                      ? dayjs(formik.values.availability_end)
                      : null
                  }
                  onChange={(date: any) => {
                    const formattedDate = date
                      ? date.format("YYYY-MM-DD")
                      : null;
                    formik.setFieldValue("availability_end", formattedDate);
                  }}
                  placeholder="Select End Date"
                  isCrossRequired={true}
                  disabledDate={(current) => {
                    const today = dayjs().startOf("day");
                    const availabilityStart = dayjs(
                      formik.values.availability_start
                    ).startOf("day");
                    // const checkInDate = dayjs(
                    //   formik.values.availability_start
                    // ).startOf("day");
                    // const minCheckOutDate = checkInDate.add(
                    //   minimumMonth,
                    //   "month"
                    // );

                    // Block all dates before check_in_date + minimumMonth
                    return (
                      (current && current.isBefore(today)) ||
                      (current && current.isBefore(availabilityStart)) ||
                      false
                    );
                  }}
                /> */}
                {/* <CustomDatePickerV2
                  date={formik.values.availability_end}
                  onDateChange={(date) => {
                    const formattedDate = date
                      ? format(date, "yyyy-MM-dd")
                      : null;
                    formik.setFieldValue("availability_end", formattedDate);
                  }}
                  placeholder="Select End Date"
                  disabledDates={[]}
                  minDate={new Date()}
                  maxDate={''}
                  isCrossRequired={true}
                /> */}

                <CustomDatePickerV2
                  name="availability_end"
                  value={formik.values.availability_end || ""}
                  onChange={(value) => {
                    const formattedDate = value
                      ? format(value, "yyyy-MM-dd")
                      : null;
                    formik.setFieldValue("availability_end", formattedDate);
                  }}
                  placeholder="Select End Date"
                  minDate={new Date()}
                  disabledDays={[]}
                  isCrossRequired={true}
                />
                {!formik.values.unlimited_availability &&
                  formik.errors.availability_end &&
                  formik.touched.availability_end && (
                    <div className="error text-red-500">
                      {formik.errors.availability_end}
                    </div>
                  )}
              </FormItem>
            )}
          </div>
          <ReactDate
            availabilityStart={formik.values.availability_start}
            availabilityEnd={formik.values.availability_end}
            unlimitedAvailability={formik.values.unlimited_availability}
            excludedDates={excludedDates}
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-end space-x-5 mt-8">
          <ButtonSecondary href={`/add-listing-6/${slug}`}>
            Go back
          </ButtonSecondary>
          <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? "Loading..." : "Continue"}
          </ButtonPrimary>
        </div>
      </form>
    </CommonLayoutV2>
  );
};

export default ListingCalender;
