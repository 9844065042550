import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getBookingsQueryDetails,
  postChatBooking,
  updateBookingsQueryDetails,
} from "../../../redux/reducers/Properties/PropertiesSlice";
import { AppDispatch, RootState } from "redux/store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLocation } from "react-router-dom";
import GallerySlider from "components/GallerySlider/GallerySlider";
import successHandler from "utils/helpers/SuccessHandler";
import { useSelector } from "react-redux";
import ErrorHandler from "utils/helpers/ErrorHandler";
import DateSelectionModal from "shared/DateField/DateSelectionModal";
import { useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import moment from "moment";

export interface BookingQueryDetails {
  className?: string;
}

const BookingQueryDetails: FC<BookingQueryDetails> = ({ className = "" }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [bookingRequestDetails, setBookingRequestDetails] = useState<
    any | null
  >(null);
  const [replyText, setReplyText] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const modifiedBookingMap = bookingRequestDetails?.messages
  ? [...bookingRequestDetails?.messages].reverse()
  : [];

  const { currentUser } = useSelector((state: RootState) => state.user);
  const { globalId } = useSelector((state: RootState) => state.property);
  const bookingId = globalId || id;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const Guest = bookingRequestDetails?.booking_query?.guest_count;

  const checkInDate = bookingRequestDetails?.booking_query?.check_in_date
    ? new Date(
        bookingRequestDetails?.booking_query?.check_in_date
      ).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : "";

  const checkOutDate = bookingRequestDetails?.booking_query?.check_out_date
    ? new Date(
        bookingRequestDetails?.booking_query?.check_out_date
      ).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : "";

  const handleFileChange = (e: { target: { files: FileList | null } }) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file && file.type.startsWith("image/")) {
        setSelectedFile(file);
      }
    }
  };

  const fetchAllManageBooking = async () => {
    const action = await dispatch(getBookingsQueryDetails(id));
    if (action?.payload?.success) {
      setBookingRequestDetails(action?.payload);
    }
  };

  // const handleSubmit = async () => {
  //   if (!replyText.trim() && !selectedFile) return;
  //   setIsSubmitting(true);

  //   try {
  //     const formData = new FormData();
  //     formData.append("message[body]", replyText);
  //     if (selectedFile) {
  //       formData.append("file", selectedFile);
  //     }

  //     const data = {
  //       chatId: bookingRequestDetails?.booking_query?.chat?.id,
  //       formData,
  //     };
  //     await dispatch(postChatBooking(data)).unwrap();
  //     setReplyText("");
  //     setSelectedFile(null);
  //     fetchAllManageBooking();
  //   } catch (error) {
  //     console.error("Failed to send reply:", error);
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  const handleSubmit = async () => {
    if (!replyText.trim() && !selectedFile) return;
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append("message[body]", replyText);
      if (selectedFile) {
        formData.append("file", selectedFile);
        formData.append("attachment", selectedFile);
        formData.append("message[attachments][]", selectedFile);
      }

      await dispatch(
        postChatBooking({
          chatId: bookingRequestDetails?.booking_query?.chat?.id,
          formData,
        })
      ).unwrap();
      setReplyText("");
      setSelectedFile(null);
      fetchAllManageBooking();
    } catch (error) {
      console.error("Failed to send reply:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateBooking = async () => {
    const id = bookingId;
    const propertyId = bookingRequestDetails?.booking_query?.property?.slug;
    const state = "booking_invitation";

    try {
      const action = await dispatch(
        updateBookingsQueryDetails({ id, propertyId, state })
      );

      if (updateBookingsQueryDetails.fulfilled.match(action)) {
        successHandler("Booking Invitation  Send successfully!");
      } else {
        ErrorHandler("Failed to update booking state.");
      }
    } catch (error) {
      ErrorHandler("Error updating booking state.");
    }
  };

  const handleDeclineBooking = async () => {
    const id = bookingId;
    const propertyId = bookingRequestDetails?.booking_query?.property?.slug;
    const state = "rejected";

    try {
      const action = await dispatch(
        updateBookingsQueryDetails({ id, propertyId, state })
      );

      if (updateBookingsQueryDetails.fulfilled.match(action)) {
        successHandler("Booking Decline successfully!");
      } else {
        ErrorHandler("Failed to Decline booking state.");
      }
    } catch (error) {
      ErrorHandler("Error updating booking state.");
    }
  };

  const handleBookingRequest = async () => {
    const id = bookingId;
    const propertyId = bookingRequestDetails?.booking_query?.property?.slug;
    const state = "accepted";
    try {
      const action = await dispatch(
        updateBookingsQueryDetails({ id, propertyId, state })
      );
      if (updateBookingsQueryDetails.fulfilled.match(action)) {
        successHandler("Booking Request Send successfully!");
        navigate(`/booking_request?id=${action.payload.booking.id}`);
      } else {
        ErrorHandler("Failed to Send booking .");
      }
    } catch (error) {
      ErrorHandler("Failed to Send booking.");
    }
  };
  const handleDateSubmit = async (dates: any) => {
    const id = bookingId;
    const propertyId = bookingRequestDetails?.booking_query?.property?.slug;
    const state = "request_change";
    const check_in_date = dates?.startDate;
    const check_out_date = dates?.endDate;

    try {
      const action = await dispatch(
        updateBookingsQueryDetails({
          id,
          propertyId,
          state,
          check_in_date,
          check_out_date,
        })
      );

      if (updateBookingsQueryDetails.fulfilled.match(action)) {
        successHandler("Booking date changed successfully!");

        // Re-fetch booking details to update the state
        await dispatch(getBookingsQueryDetails(id));
      } else {
        ErrorHandler("Failed to change the date.");
      }
    } catch (error) {
      ErrorHandler("Failed to send booking request.");
    }
  };

  useEffect(() => {
    fetchAllManageBooking();
  }, [dispatch]);

  useEffect(() => {
    if (
      bookingRequestDetails?.booking_query?.state === "accepted" &&
      currentUser?.is_host
    ) {
      navigate(`/booking_request?id=${bookingRequestDetails?.booking?.id}`);
    }
  }, [bookingRequestDetails, navigate]);

  const renderSliderGallery = () => (
    <div className="w-full">
      <GallerySlider
        uniqueID={`StayCard_${id}`}
        ratioClass="aspect-w-4 aspect-h-3"
        galleryImgs={
          bookingRequestDetails?.booking_query?.property?.place_images || []
        }
        href={"#"}
      />
    </div>
  );

  const pathArray = [
    { path: 1, type: "Receive a message", state: "send_message" },
    { path: 2, type: "Send invitation to book", state: "booking_invitation" },
    { path: 3, type: "Wait for confirmation", state: "invitation_sent" },
    {
      path: 4,
      type: "Wait for payment of first month",
      state: "invoice_sent",
    },
    { path: 5, type: "Booking confirmed", state: "confirmed" },
  ];

  return (
    <div className="max-w-7xl mx-auto p-4 lg:container ">
      <h1 className="text-xl font-bold text-gray-700 dark:text-gray-200 mb-6">
        MESSAGE FROM{" "}
        {currentUser?.is_host
          ? bookingRequestDetails?.booking_query?.chat?.sender?.name ||
            "Yopmail"
          : bookingRequestDetails?.booking_query?.chat?.receiver?.name ||
            "Host"}
      </h1>

      <div className="mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-2 mb-8">
          <div className="lg:col-span-2">
            <div className="relative flex flex-col md:flex-row justify-between items-center border border-gray-300 dark:bg-black  p-0.8  pt-4 md:p-8 text-xl  backdrop-blur-lg shadow-xl rounded-lg ">
              <div className="relative flex flex-col md:flex-row justify-between items-center w-full">
                {pathArray.map((step, index) => {
                  const activeStepIndex = pathArray.findIndex((s) => {
                    return (
                      s.state === bookingRequestDetails?.booking_query?.state ||
                      1
                    );
                  });
                  return (
                    <div
                      key={step.path}
                      className="flex flex-col  items-center md:w-1/5 w-full mb-4 md:mb-0 relative z-10"
                    >
                      <div
                        className={`w-4 h-4 rounded-full flex items-center justify-center mb-2 text-xs p-4 ${
                          index <= activeStepIndex
                            ? "bg-orange-500 text-white "
                            : "bg-gray-300 dark:bg-gray-700 text-gray-500"
                        }`}
                      >
                        {step.path}
                      </div>
                      <span className="text-xs text-center dark:text-gray-300 w-[105px]">
                        {step.path === 1 ? (
                          currentUser?.is_host ? (
                            "Receive a message"
                          ) : (
                            <>
                              Send
                              <br />a message
                            </>
                          )
                        ) : step.path === 2 ? (
                          currentUser?.is_host ? (
                            "Send invitation to book"
                          ) : (
                            <>
                              Send
                              <br />
                              booking request
                            </>
                          )
                        ) : (
                          step.type
                        )}
                      </span>
                      {index < pathArray.length - 1 && (
                        <div
                          className={`absolute top-1 left-full transform -translate-x-1/2 h-[2px] w-[60%] sm:w-3/4 mt-2 hidden md:block ${
                            index < activeStepIndex
                              ? "bg-orange-500"
                              : index === activeStepIndex -1
                              ? "bg-orange-500"
                              : "bg-gray-300 dark:bg-gray-700"
                          }`}
                        ></div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="bg-white dark:bg-black shadow-lg rounded-lg p-8 mt-5 border border-gray-300 dark:border-gray-700">
              <div className="flex flex-col gap-2">
                {bookingRequestDetails?.booking_query?.state === "rejected" ? (
                  <p className="text-sm text-red-600 dark:text-red-400 font-bold flex items-center justify-center h-full">
                    Request Declined
                  </p>
                ) : currentUser?.is_host ? (
                  <>
                    <p className="te xt-sm text-gray-600 dark:text-gray-300 mb-2">
                      Invite{" "}
                      <span className="font-semibold mb-4 text-xl text-gray-700 dark:text-gray-200">
                        {bookingRequestDetails?.booking_query?.chat?.sender
                          ?.name || "Yopmail"}
                      </span>{" "}
                      to book your place.
                      <span className="block mt-1">
                        They will be able to book your place immediately within
                        the next 48 hours after you invite them to book.
                      </span>
                    </p>
                    <div className="flex gap-2">
                      {bookingRequestDetails?.booking_query?.state ===
                      "booking_invitation" ? (
                        <ButtonPrimary
                          className="bg-gray-500 text-white px-4 py-2 rounded-lg cursor-default"
                          disabled={true}
                        >
                          Invited
                        </ButtonPrimary>
                      ) : (
                        <ButtonPrimary
                          className="bg-green-500 text-white px-4 py-2 rounded-lg"
                          onClick={handleUpdateBooking}
                        >
                          Invite to book
                        </ButtonPrimary>
                      )}
                      <ButtonPrimary
                        className="bg-orange-500 text-white px-4 py-2 rounded-lg"
                        onClick={handleDeclineBooking}
                      >
                        Decline
                      </ButtonPrimary>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                      Move forward with booking this place. Request a booking
                      from
                      <span className="font-bold text-gray-800 dark:text-gray-100">
                        {" "}
                        {bookingRequestDetails?.booking_query?.chat?.receiver
                          ?.name || "Temp"}{" "}
                      </span>
                      in order to secure this place. You will not be charged
                      until your request has been accepted by
                      <span className="font-bold">
                        {" "}
                        {bookingRequestDetails?.booking_query?.chat?.receiver
                          ?.name || "Temp"}
                        .
                      </span>
                    </p>
                    <div className="flex gap-2">
                      <ButtonPrimary
                        className="bg-orange-500 text-white px-4 py-2 rounded-lg"
                        onClick={handleBookingRequest}
                      >
                        Booking Request
                      </ButtonPrimary>
                      <ButtonPrimary
                        className="bg-orange-500 text-white px-4 py-2 rounded-lg"
                        disabled={bookingRequestDetails?.status === "canceled"}
                        onClick={() => setIsModalOpen(true)}
                      >
                        Change Requested Dates
                      </ButtonPrimary>

                      <DateSelectionModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        onSubmit={handleDateSubmit}
                        bookingRequestDetails={bookingRequestDetails}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="mt-6 p-6 bg-gray-100 dark:bg-black rounded-lg">
                <span className="text-gray-500 dark:text-gray-400">Reply</span>
                <textarea
                  className="w-full border border-gray-300 dark:border-gray-700 rounded-lg p-3 min-h-[80px] focus:outline-none focus:border-gray-400 dark:focus:border-gray-600 mt-4 bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-300"
                  placeholder="Write your reply..."
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  disabled={isSubmitting}
                />
                <div className="flex justify-end items-center mt-4 gap-6">
                  <label
                    htmlFor="cover-image-upload"
                    className="text-blue-500 dark:text-blue-400 text-sm cursor-pointer flex items-center gap-1"
                  >
                    📎 <span>Upload file</span>
                    <input
                      id="cover-image-upload"
                      name="cover-image-upload"
                      type="file"
                      accept="image/*"
                      className="sr-only"
                      onChange={handleFileChange}
                      disabled={isSubmitting}
                    />
                  </label>
                  {selectedFile && (
                    <span className="text-sm text-gray-600 dark:text-gray-400">
                      {selectedFile?.name}
                    </span>
                  )}
                  <button
                    onClick={handleSubmit}
                    disabled={
                      isSubmitting || (!replyText.trim() && !selectedFile)
                    }
                    className={`bg-orange-500 text-white px-6 py-3 rounded-lg ${
                      isSubmitting || (!replyText.trim() && !selectedFile)
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-orange-600"
                    }`}
                  >
                    {isSubmitting ? "Sending..." : "Send Reply"}
                  </button>
                </div>
              </div>
              <div className="mt-6 border border-gray-300 p-6 space-y-6 bg-white dark:bg-black dark:border-gray-700 rounded-lg">
                {modifiedBookingMap?.map((msg: any, index: number) => (
                  <div key={index}>
                    {msg.sender?.id ? (
                      <div className="flex items-start space-x-4 p-4 bg-gray-100 dark:bg-black dark:border-white rounded-lg">
                        <div className="flex-shrink-0">
                          {msg.sender.image ? (
                            <NcImage
                              src={msg.sender.image}
                              alt="Sender"
                              className="w-16 h-16 sm:w-12 sm:h-12 rounded-full object-cover ring-2 ring-gray-300 dark:ring-gray-600"
                            />
                          ) : (
                            <div className="w-16 h-16 sm:w-12 sm:h-12 rounded-full flex items-center justify-center bg-purple-300 text-white ring-2 ring-gray-300 dark:ring-gray-600">
                              <span className="text-xl font-semibold text-white">
                                {msg.sender.name
                                  ? msg.sender.name.charAt(0).toUpperCase()
                                  : "?"}
                              </span>
                            </div>
                          )}
                        </div>

                        <div>
                          <div className="flex-1">
                            <div className="flex items-center space-x-2">
                              <h3 className="text-sm font-semibold text-gray-700 dark:text-gray-200">
                                {msg.sender.name || "User"}
                              </h3>
                            </div>
                            <p className="text-sm text-gray-500 dark:text-gray-400 font-bold mt-1">
                              {msg?.body}
                            </p>
                          </div>
                          {/* <p className="text-sm text-gray-600 dark:text-gray-300">
                            Selected dates: {checkInDate} to {checkOutDate}
                          </p> */}
                        </div>
                      </div>
                    ) : (
                      <div className="border-b border-gray-300 dark:border-gray-700 pb-4 text-sm text-gray-600 dark:text-gray-300">
                        <span className="font-semibold text-gray-700 dark:text-gray-200">
                          System Message:
                        </span>
                        <p className="leading-8">{msg?.body}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="relative bg-orange-500 backdrop-blur-lg shadow-xl rounded-lg p-6 w-full h-96 ">
            <div className="flex flex-col items-center">
              <h3 className="relative w-24 h-24 mb-4">
                {bookingRequestDetails?.booking_query?.chat?.sender?.image ? (
                  <NcImage
                    src={
                      bookingRequestDetails?.booking_query?.chat?.sender?.image
                    }
                    alt="User"
                    className="w-24 h-24 rounded-full object-cover border-4 border-orange-500 shadow-md"
                  />
                ) : (
                  <div className="w-24 h-24 flex items-center justify-center bg-orange-600 text-white text-4xl font-bold rounded-full shadow-md">
                    {bookingRequestDetails?.booking_query?.user?.first_name
                      ?.charAt(0)
                      ?.toUpperCase() || "U"}
                  </div>
                )}
              </h3>
              <h3 className="font-bold text-lg text-white dark:text-orange-300">
                {bookingRequestDetails?.booking_query?.user?.first_name}
              </h3>
              <div className="mt-4 w-full space-y-3 text-center">
                <p className="text-sm text-white dark:text-gray-300">
                  {bookingRequestDetails?.booking_query?.user?.live_in
                    ? `🌍 Nationality: ${bookingRequestDetails?.booking_query?.user?.live_in}`
                    : "🌍 Nationality: Not available"}
                </p>
                <p className="text-sm text-white dark:text-gray-300">
                  {bookingRequestDetails?.booking_query?.user?.speak_language
                    ? `🗣️ Language: ${bookingRequestDetails?.booking_query?.user?.speak_language}`
                    : "🗣️ Language: Not available"}
                </p>
                <div className="flex items-center justify-center mr-10">
                  <p className="text-sm text-white dark:text-gray-300 flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 8l8.293 5.297a1 1 0 0 0 1.414 0L21 8m0 0L12 14.5 3 8m18-2h-4a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2H1m20 0v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6"
                      />
                    </svg>
                    <span className="mr-2">Email:</span>
                    <span
                      className={
                        bookingRequestDetails?.booking_query?.user
                          ?.email_verified
                          ? "text-green-400 font-medium"
                          : "text-red-400 font-medium"
                      }
                    >
                      {bookingRequestDetails?.booking_query?.user
                        ?.email_verified
                        ? "✓ Verified"
                        : "✘ Not Verified"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-5">
            <div className="p-6 w-full flex flex-col items-center justify-center relative bg-white dark:bg-black backdrop-blur-lg shadow-xl rounded-lg ">
              <div className="space-y-4 w-full">
                <div className="space-y-1">
                  <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
                    {bookingRequestDetails?.booking_query?.property?.title}
                  </h2>
                  <p className="text-gray-600 dark:text-gray-400">
                    {bookingRequestDetails?.booking_query?.property?.address}
                  </p>
                </div>

                <div
                  className="nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow w-full"
                  data-nc-id=""
                >
                  <div className="w-full h-full">
                    {renderSliderGallery()}
                    {/* <Link to={data?.href || "#"}>{renderContent()}</Link>   */}
                  </div>
                </div>

                <div className="space-y-3 w-full">
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Period:
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {moment(checkInDate).format("MMM DD, YYYY")} to{" "}
                      {moment(checkOutDate).format("MMM DD, YYYY")}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      No. of Months:
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      1
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Guests:
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {Guest}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingQueryDetails;
