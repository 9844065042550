import React, { FC, useEffect, useState } from "react";
// @ts-ignore
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import {
  createProperties,
  getallupdateProperties,
  getCancellation,
  getPropertyCategories,
  getPropertyType,
  setId,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import { useNavigate, useParams } from "react-router-dom";
import FormItem from "./FormItem";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormField from "shared/FormField/FormField";
import __countryListing from "../../data/jsons/__countryListing.json";
import { descriptionValidationSchemas } from "utils/formSchema";
import successHandler from "utils/helpers/SuccessHandler";
import Textarea from "shared/Textarea/Textarea";
import { toast } from "react-toastify";
import CommonLayoutV2 from "./CommonLayoutV2";
import CustomModal from "shared/Modal/CustomModal";

export interface DescriptionProps {}

const Description: FC<DescriptionProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { slug } = useParams();
  const id = slug;

  const [filteredPropertyTypes, setFilteredPropertyTypes] = useState<any[]>([]);
  const [filteredCancellationTypes, setfilteredCancellationTypes] = useState<
    any[]
  >([]);
  const [modalDescription, setModalDescription] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const {
    globalId,
    propertiesCategories,
    propertyTypes,
    PropertyToEdit,
    cancelTypes,
  } = useSelector((state: RootState) => state.property);
  const RouteID = slug;

  const initialValues = {
    title: PropertyToEdit?.title || "",
    property_category: PropertyToEdit?.property_category?.id || "",
    property_type_id: PropertyToEdit?.property_type?.id || "",
    country: PropertyToEdit?.country || "",
    street: PropertyToEdit?.address || "",
    city: PropertyToEdit?.city || "",
    state: PropertyToEdit?.state || "",
    zipcode: PropertyToEdit?.zipcode || "",
    guests: PropertyToEdit?.guest_number || 0,
    description: PropertyToEdit?.description || "",
    cancellation_policy_id: PropertyToEdit?.cancellation_policy?.id || "",
    bedroom_description: PropertyToEdit?.bedroom_description,
    university_nearby: PropertyToEdit?.university_nearby,
    about_neighbourhoods: PropertyToEdit?.about_neighbourhoods,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: descriptionValidationSchemas,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload = {
        title: values.title,
        property_category_id: values.property_category,
        property_type_id: values.property_type_id,
        address: values.street,
        city: values.city,
        state: values.state,
        country: values.country,
        zipcode: values.zipcode,
        guest_number: values.guests,
        description: values.description,
        cancellation_policy_id: values.cancellation_policy_id,
        bedroom_description: values.bedroom_description,
        university_nearby: values.university_nearby,
        about_neighbourhoods: values.about_neighbourhoods,
        ...(slug ? { id: slug } : {}),
      };

      setSubmitting(true);

      if (id) {
        if (isEditing) {
          dispatch(updateProperties({ propertyId: id, property: payload }))
            .unwrap()
            .then((response) => {
              const newSlug = response?.property?.slug || slug
              if (response?.success) {
                successHandler("Updated successfully");
                navigate(`/add-listing-2/${newSlug}`);
                resetForm();
              }
            })
            .catch(console.error)
            .finally(() => setSubmitting(false));
        } else {
          navigate(`/add-listing-2/${slug}`);
        }
      } else {
        dispatch(createProperties({ property: payload }))
          .unwrap()
          .then((response) => {
            if (response?.success) {
              successHandler("Created successfully");
              dispatch(setId(response.property.id));
              resetForm();
              navigate(`/add-listing-2/${response?.property?.slug}`);
            }
          })
          .catch(console.error)
          .finally(() => setSubmitting(false));
      }
    },
  });

  const initialFormValues = JSON.stringify(initialValues);

  useEffect(() => {
    const currentFormValues = JSON.stringify(formik.values);
    setIsEditing(currentFormValues !== initialFormValues);
  }, [formik.values, initialFormValues]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    if (name === "cancellation_policy_id") {
      const selectedOption = filteredCancellationTypes.find(
        (type) => String(type.id) === value
      );
      if (selectedOption?.description) {
        setIsModalOpen(true);
        setModalDescription(selectedOption.description);
      }
    }
    formik.handleChange(event);
  };

  useEffect(() => {
    dispatch(getPropertyCategories());
    dispatch(getPropertyType());
    dispatch(getCancellation());
    const propertyId = id;
    dispatch(getallupdateProperties({ propertyId }));
  }, [dispatch, globalId, id]);

  useEffect(() => {
    if (Array.isArray(propertyTypes)) {
      setFilteredPropertyTypes(propertyTypes);
    }
  }, [propertyTypes]);

  useEffect(() => {
    if (Array.isArray(cancelTypes)) {
      setfilteredCancellationTypes(cancelTypes);
    }
  }, [cancelTypes]);

  return (
    <CommonLayoutV2 currentHref="/add-listing-1" PropertyID={RouteID}>
      <>
        <h2 className="text-2xl font-semibold">Listing Details</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <form onSubmit={formik.handleSubmit}>
          <div className="space-y-8">
            <FormItem
              label="Title"
              desc="Provide a descriptive name for your property."
            >
              <Input
                placeholder="Title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="text-red-500">{formik.errors.title}</div>
              )}
            </FormItem>

            <FormItem desc="Select a property category from the available options.">
              <FormField
                label="Choose a property Category"
                name="property_category"
                type="select"
                value={formik.values.property_category}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue("property_type_id", "");
                }}
                onBlur={formik.handleBlur}
                options={
                  propertiesCategories?.map((item) => ({
                    value: String(item.id),
                    label: item.name,
                  })) || []
                }
                error={
                  formik.touched.property_category &&
                  formik.errors.property_category
                    ? formik.errors.property_category
                    : undefined
                }
              />
            </FormItem>
            <FormItem>
              <FormField
                label="Choose a property type"
                name="property_type_id"
                type="select"
                value={formik.values.property_type_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={filteredPropertyTypes.map((type) => ({
                  value: String(type.id),
                  label: type.name,
                }))}
                error={
                  formik.touched.property_type_id &&
                  formik.errors.property_type_id
                    ? formik.errors.property_type_id
                    : undefined
                }
              />
            </FormItem>
            <FormItem label="Country/Region">
              <Select
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select a country</option>
                {__countryListing.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Select>
              {formik.touched.country && formik.errors.country && (
                <div className="text-red-600">{formik.errors.country}</div>
              )}
            </FormItem>
            {formik.values.country === "United States" && (
              <FormItem label="City">
                <Input
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="text-red-600">{formik.errors.city}</div>
                )}
              </FormItem>
            )}
            <FormItem label="Number of Guests">
              <Select
                {...formik.getFieldProps("guests")}
                className="border rounded px-2 py-1"
              >
                <option value="">Select number of guests</option>
                {Array.from({ length: 14 }, (_, i) => i + 1).map((guest) => (
                  <option key={guest} value={guest}>
                    {guest}
                  </option>
                ))}
              </Select>
              {formik.touched.guests && formik.errors.guests && (
                <div className="text-red-600">{formik.errors.guests}</div>
              )}
            </FormItem>

            {/* <FormItem label="Street">
              <Input
                name="street"
                value={formik.values.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter street"
              />
              {formik.touched.street && formik.errors.street && (
                <div className="text-red-600">{formik.errors.street}</div>
              )}
            </FormItem> */}

            {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-8"> */}
            {/* <FormItem label="State">
                <Input
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className="text-red-600">{formik.errors.state}</div>
                )}
              </FormItem> */}
            {/* <FormItem label="Postal Code">
                <Input
                  name="zipcode"
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.zipcode && formik.errors.zipcode && (
                  <div className="text-red-600">{formik.errors.zipcode}</div>
                )}
              </FormItem> */}
            {/* </div> */}
            <FormItem label="Description">
              <Textarea
                placeholder="Describe your property"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.description && formik.errors.description && (
                <div className="text-red-600">{formik.errors.description}</div>
              )}
            </FormItem>

            <FormItem label="Cancellation Policy">
              <Select
                name="cancellation_policy_id"
                value={formik.values.cancellation_policy_id}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.touched.cancellation_policy_id &&
                  formik.errors.cancellation_policy_id
                    ? "error-border"
                    : ""
                }
              >
                <option value="">Select a cancellation policy</option>
                {Array.isArray(filteredCancellationTypes) &&
                  filteredCancellationTypes.map((type: any) => (
                    <option key={type.id} value={String(type.id)}>
                      {type.name}
                    </option>
                  ))}
              </Select>
              {formik.touched.cancellation_policy_id &&
                formik.errors.cancellation_policy_id && (
                  <div className="text-red-600">
                    {typeof formik.errors.cancellation_policy_id === "string" &&
                      formik.errors.cancellation_policy_id}
                  </div>
                )}
            </FormItem>

            <FormItem label="Universities Nearby">
              <Textarea
                placeholder="Which Universities close to property"
                name="university_nearby"
                value={formik.values.university_nearby || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.university_nearby &&
                formik.errors.university_nearby && (
                  <div className="text-red-600">
                    {formik.errors.university_nearby}
                  </div>
                )}
            </FormItem>
            <FormItem label="About Neighborhood">
              <Textarea
                placeholder="Describe the neighborhood"
                name="about_neighbourhoods"
                value={formik.values.about_neighbourhoods || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.about_neighbourhoods &&
                formik.errors.about_neighbourhoods && (
                  <div className="text-red-600">
                    {formik.errors.about_neighbourhoods}
                  </div>
                )}
            </FormItem>
          </div>
          <div className="flex justify-end space-x-5 my-5">
            <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
              {formik.isSubmitting ? "Loading..." : "Continue"}
            </ButtonPrimary>
          </div>
        </form>
        <CustomModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          description={modalDescription}
        />
      </>
    </CommonLayoutV2>
  );
};

export default Description;
