import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AxiosError } from "axios";

// Custom Error Handler
export default function ErrorHandler(error: any) {
  if (error instanceof AxiosError) {
    const { response } = error;

    if (response) {
      if (response.status!==404) {
        toast.error(response.data.error || response.data.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } else {
      toast.error("Internal server error", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  }
}
