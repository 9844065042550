import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import FormItem from "./FormItem";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  updateProperties,
  getallupdateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { PricevalidationSchemas } from "utils/formSchema";
import CheckboxV2 from "shared/Checkbox/CheckboxV2";
import CommonLayoutV2 from "./CommonLayoutV2";
import { currencySymbol } from "shared/CurrencySymbol";
import successHandler from "utils/helpers/SuccessHandler";

export interface PriceProps {}

interface InitialValues {
  price_per_month: string;
  taxes_in_percentage: string;
  cleaning_fee: string;
  city_fee: string;
  is_city_fee_percentage: boolean;
  minimum_months_of_booking: string;
  security_deposit: string;
  early_bird_discount: string;
  advance_days: string;
  per_extra_guest_amount: string;
  allow_extra_guest: boolean;
  isSharedProperty: boolean;
}

const Price: FC<PriceProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { globalId } = useSelector((state: RootState) => state.property);
  const { slug } = useParams();
  const id = slug;
  const RouteID = id;

  const [initialValues, setInitialValues] = useState({
    price_per_month: "",
    taxes_in_percentage: "",
    cleaning_fee: "",
    city_fee: "",
    is_city_fee_percentage: false,
    minimum_months_of_booking: "",
    security_deposit: "",
    early_bird_discount: "",
    advance_days: "",
    per_extra_guest_amount: "",
    allow_extra_guest: false,
    isSharedProperty: false,
  });

  useEffect(() => {
    if (globalId || id) {
      const fetchPropertyData = async () => {
        const response: any = await dispatch(
          getallupdateProperties({ propertyId: id })
        );
        const property = response.payload?.property;
        if (property) {
          setInitialValues({
            price_per_month: property.price_per_month || "",
            taxes_in_percentage: property.taxes_in_percentage || "",
            cleaning_fee: property.cleaning_fee,
            city_fee: property.city_fee,
            is_city_fee_percentage: property.is_city_fee_percentage || false,
            minimum_months_of_booking: property.minimum_months_of_booking,
            security_deposit: property.security_deposit,
            early_bird_discount: property.early_bird_discount,
            advance_days: property.advance_days,
            per_extra_guest_amount: property.per_extra_guest_amount,
            allow_extra_guest: property.allow_extra_guest,
            isSharedProperty: property.is_shared_property,
          });
        } else {
          console.error("Property not found:", property);
        }
      };

      fetchPropertyData();
    }
  }, [dispatch, globalId]);

  const isDirty = (
    values: typeof initialValues,
    initialValues: InitialValues
  ) =>
    (Object.keys(values) as (keyof InitialValues)[]).some(
      (key) => values[key] !== initialValues[key]
    );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: PricevalidationSchemas,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const dirty = isDirty(values, initialValues);
      const payload = {
        id: id,
        price_per_month: values.price_per_month,
        taxes_in_percentage: values.taxes_in_percentage,
        cleaning_fee: values.cleaning_fee,
        city_fee: values.city_fee,
        is_city_fee_percentage: values.is_city_fee_percentage,
        minimum_months_of_booking: values.minimum_months_of_booking,
        security_deposit: values.security_deposit,
        early_bird_discount: values.early_bird_discount,
        advance_days: values.advance_days,
        per_extra_guest_amount: values.allow_extra_guest
          ? values.per_extra_guest_amount
          : undefined,
        allow_extra_guest: values.allow_extra_guest,
      };

      setSubmitting(true);
      if (dirty) {
        dispatch(updateProperties({ property: payload }))
          .unwrap()
          .then((response: any) => {
            if (response) {
              successHandler(response?.message || "Updated successfully");
              resetForm();
              navigate(`/add-listing-3/${slug}`);
            }
          })
          .catch((error: any) => {
            console.error("Error updating property: ", error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        navigate(`/add-listing-3/${slug}`);
      }
    },
  });

  return (
    <CommonLayoutV2 currentHref="/add-listing-2" PropertyID={RouteID}>
      <>
        <div>
          <h2 className="text-2xl font-semibold">Price your space</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            The host's revenue is directly dependent on the setting of rates and
            regulations on the number of guests, the number of nights, and the
            cancellation policy.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <form onSubmit={formik.handleSubmit}>
          <div className="space-y-8">
            {!initialValues.isSharedProperty && (
              <FormItem label="Price per month">
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    {currencySymbol()}
                  </span>
                  <Input
                    name="price_per_month"
                    type="text"
                    placeholder="Please enter the price per month"
                    className="pl-8"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.price_per_month}
                  />
                </div>
                {formik.errors.price_per_month &&
                  formik.touched.price_per_month && (
                    <div className="text-red-600">
                      {formik.errors.price_per_month}
                    </div>
                  )}
              </FormItem>
            )}
            <FormItem label="Taxes in % (taxes are considered included in the monthly price)">
              <div className="relative">
                <Input
                  name="taxes_in_percentage"
                  type="text"
                  value={formik.values.taxes_in_percentage}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d*$/.test(value)) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Taxes"
                  className="pr-8"
                />
                <span className="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-500">
                  %
                </span>
              </div>

              {formik.touched.taxes_in_percentage &&
                formik.errors.taxes_in_percentage && (
                  <div className="text-red-600">
                    {formik.errors.taxes_in_percentage}
                  </div>
                )}
            </FormItem>
            <FormItem label="Cleaning Fee">
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                  {currencySymbol()}
                </span>
                <Input
                  name="cleaning_fee"
                  type="text"
                  value={formik.values.cleaning_fee}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d*$/.test(value)) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Please enter the cleaning fee"
                  className="pl-8"
                />
              </div>
              {formik.touched.cleaning_fee && formik.errors.cleaning_fee && (
                <div className="text-red-600">{formik.errors.cleaning_fee}</div>
              )}
            </FormItem>
            <div className="grid grid-cols-1 gap-8 items-center">
              <FormItem label="City Fee per month: USD (only numbers)">
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    {currencySymbol()}
                  </span>
                  <Input
                    name="city_fee"
                    type="text"
                    value={formik.values.city_fee}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Please enter the city fee"
                    className="pl-8"
                  />
                </div>
                {formik.touched.city_fee && formik.errors.city_fee && (
                  <div className="text-red-600">{formik.errors.city_fee}</div>
                )}
              </FormItem>
              {/* <FormItem label="">
                <CheckboxV2
                  name="is_city_fee_percentage"
                  label="City Fee is a % of the Monthly fee"
                  onChange={(checked) => formik.setFieldValue("is_city_fee_percentage", checked)}
                  checked={formik.values.is_city_fee_percentage}/>
              </FormItem> */}
            </div>
            <FormItem label="Minimum months of booking (only numbers) *">
              <Input
                name="minimum_months_of_booking"
                type="text"
                value={formik.values.minimum_months_of_booking}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*\.?\d*$/.test(value)) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                placeholder="Please enter the minimum months of booking"
              />
              {formik.touched.minimum_months_of_booking &&
                formik.errors.minimum_months_of_booking && (
                  <div className="text-red-600">
                    {formik.errors.minimum_months_of_booking}
                  </div>
                )}
            </FormItem>
            <FormItem label="Security Deposit in USD - will be refunded if no complaints are received from the owner">
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                  {currencySymbol()}
                </span>
                <Input
                  name="security_deposit"
                  type="text"
                  value={formik.values.security_deposit}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d*$/.test(value)) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Please enter the security deposit"
                  className="pl-8"
                />
              </div>
              {formik.touched.security_deposit &&
                formik.errors.security_deposit && (
                  <div className="text-red-600">
                    {formik.errors.security_deposit}
                  </div>
                )}
            </FormItem>
            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <FormItem label="Early Bird Discount - in % from the price per night">
                <Input
                  name="early_bird_discount"
                  type="number"
                  value={formik.values.early_bird_discount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="0"
                />
                {formik.touched.early_bird_discount && formik.errors.early_bird_discount && (
                  <div className="text-red-600">{formik.errors.early_bird_discount}</div>
                )}
              </FormItem>
              <FormItem label="No of days in advance">
                <Input
                  name="advance_days"
                  type="number"
                  value={formik.values.advance_days}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="0"
                />
                {formik.touched.advance_days && formik.errors.advance_days && (
                  <div className="text-red-600">{formik.errors.advance_days}</div>
                )}
              </FormItem>
              </div> */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <FormItem label="Extra Price per guest per month USD">
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    {currencySymbol()}
                  </span>
                  <Input
                    name="per_extra_guest_amount"
                    type="text"
                    value={formik.values.per_extra_guest_amount}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Please enter the extra price per guest"
                    className="pl-8"
                    disabled={!formik.values.allow_extra_guest}
                  />
                </div>
                {formik.touched.per_extra_guest_amount &&
                  formik.errors.per_extra_guest_amount && (
                    <div className="text-red-600">
                      {formik.errors.per_extra_guest_amount}
                    </div>
                  )}
              </FormItem>

              <div className="md:mt-5 mt-0">
                <FormItem label="">
                  <CheckboxV2
                    name="allow_extra_guest"
                    label="Allow guests above capacity?"
                    onChange={(checked) =>
                      formik.setFieldValue("allow_extra_guest", checked)
                    }
                    checked={formik.values.allow_extra_guest}
                  />
                </FormItem>
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-5 mt-8">
            <ButtonSecondary href={`/add-listing-1/${id}`}>
              Go back
            </ButtonSecondary>
            <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
              {formik.isSubmitting ? "Loading..." : "Continue"}
            </ButtonPrimary>
          </div>
        </form>
      </>
    </CommonLayoutV2>
  );
};

export default Price;
