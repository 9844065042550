import React, { FC, ReactNode } from "react";
import { StayDataType } from "data/types";
import HeaderFilterV2 from "./HeaderFilterV2";
import StayCardV2 from "components/StayCard/StayCardV2";

export interface SectionGridFeaturePlacesV2Props {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlacesV2: FC<SectionGridFeaturePlacesV2Props> = ({
  stayListings = [],
  gridClass = "",
  heading = "Booking Query",
  subHeading = "",
}) => {
  const renderCard = (stay: StayDataType) => {
    return <StayCardV2 key={stay.id} data={stay} />;
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilterV2 subHeading={subHeading} heading={heading} />

      {stayListings.length > 0 ? (
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 ${gridClass}`}
        >
          {stayListings?.map((stay) => renderCard(stay))}
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-neutral-900 dark:text-neutral-100">
              No Booking Query Found
            </h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionGridFeaturePlacesV2;
